import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import style from '../../css/layout.module.css';
import { mdiDeleteCircle } from '@mdi/js';
import Icon from '@mdi/react';
import modalStyle from '../../css/globaltable.module.css';

import AgencyService from "../services/AgencyService";
import CountyService from "../services/CountyService";
import EntityService from "../services/EntityService";
import ClliService from "../services/ClliService";
import ExchangeService from "../services/ExchangeService";

class DeleteModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: '',
            title1:('Are you sure you want to delete '  + (this.props.deleteType==="batch"?'these entries?':'this entry?')),
            title2: 'This action cannot be undone',
        }


        this.initialState = this.state;

        this.deleteGeneral = this.deleteGeneral.bind(this);
        this.deleteAgency = this.deleteAgency.bind(this);
        this.deleteCounty = this.deleteCounty.bind(this);
        this.deleteEntity = this.deleteEntity.bind(this);
        this.deleteClli = this.deleteClli.bind(this);
        this.deleteExchange = this.deleteExchange.bind(this);

}


deleteGeneral = (e) => {

    e.preventDefault();

    switch (this.props.entityType) {

        case "agency":   
           this.deleteAgency(e);
           return;

        case "county":   
           this.deleteCounty(e);
           return;

        case "entity":   
           this.deleteEntity(e);
           return;

        case "clli":   
           this.deleteClli(e);
           return;

        case "exchange":   
           this.deleteExchange(e);
           return;

    }    

}

deleteAgency = (e) => {

    let agency = {agencyId:this.props.agencyId,idNUm:this.props.rowId 
                 };


        AgencyService.deleteAgency(agency.agencyId)
        .then(res =>{
                this.cleanSingleValues();
        }
        )
        .catch(err =>{
            this.setState({message:err.message});
        });
}

deleteCounty = (e) => {

    if(this.props.deleteType == "single"){
        let county = {countyCd:this.props.countyCd,idNum:this.props.rowId 
                    };

            CountyService.deleteCountybyId(county.idNum)
            .then(res =>{

                this.cleanSingleValues();
                this.props.navigate('/county');
                    
                }
            )
            .catch(err =>{
                this.setState({message:err.message});
            });
        }    
        if(this.props.deleteType == "batch"){

            let arrayCounty = [];

            this.props.dataList.map(dataCounty => {
                let county = {idNum:dataCounty};
                arrayCounty.push(county);
            })


            CountyService.deleteBatchCounty(arrayCounty)
            .then(res =>{
                    this.cleanBatchValues();
                    this.props.navigate('/county');
                }
            )
            .catch(err =>{
                this.setState({message:err.message});
            });

            
    
        }    

}

deleteEntity = (e) => {
    if(this.props.deleteType == "single"){
        let entity = {entity:this.props.entity,idNum:this.props.rowId 
                     };

            EntityService.deleteEntityById(entity.idNum)
            .then(res =>{
                    this.cleanSingleValues();
                    this.props.navigate('/entity');
            }
            )
            .catch(err =>{
                this.setState({message:err.message});
            });
     }   
     if(this.props.deleteType == "batch"){

        let arrayEntity = [];

        this.props.dataList.map(dataEntity => {
            let entity = {idNum:dataEntity};
            arrayEntity.push(entity);
        })


        EntityService.deleteBatchEntity(arrayEntity)
        .then(res =>{
                this.cleanBatchValues();
                this.props.navigate('/entity');

            }
        )
        .catch(err =>{
            this.setState({message:err.message});
        });
    }    

 }

 deleteClli = (e) => {
    if(this.props.deleteType == "single"){

        let clli = {clliCd:this.props.clliCd,idNum:this.props.rowId 
                    };

            ClliService.deleteClliById(clli.idNum)
            .then(res =>{
                   this.cleanSingleValues();
                   this.props.navigate('/clli');
                }
            )
            .catch(err =>{
                this.setState({message:err.message});
            });
    }        
    if(this.props.deleteType == "batch"){

            let arrayClli = [];

            this.props.dataList.map(dataClli => {
                let clli = {idNum:dataClli};
                arrayClli.push(clli);
            })

            ClliService.deleteBatchClli(arrayClli)
            .then(res =>{
                this.cleanBatchValues();
                this.props.navigate('/clli');
                }
            )
            .catch(err =>{
                this.setState({message:err.message});
            });
    }    
}

deleteExchange = (e) => {
    if(this.props.deleteType == "single"){

        let exchange = {exchange:this.props.exchange,idNum:this.props.rowId 
                    };
            ExchangeService.deleteExchangeById(exchange.idNum)
            .then(res =>{

                this.cleanSingleValues();
                this.props.navigate('/exchange');
            }
            )
            .catch(err =>{
                this.setState({message:err.message});
            });
    }        
    if(this.props.deleteType == "batch"){

        let arrayExchange = [];

        this.props.dataList.map(dataExchange => {
            let exchange = {idNum:dataExchange};
            arrayExchange.push(exchange);
        })


        ExchangeService.deleteBatchExchange(arrayExchange)
        .then(res =>{
            this.cleanBatchValues();
            this.props.navigate('/exchange');
            }
        )
        .catch(err =>{
            this.setState({message:err.message});
        });
    }    
}

cancel(){
  {this.props.onCloseDeleteModal()}
}

cleanBatchValues() {
    {this.props.getDataWithAxios(this.props.baseURL)}
    {this.props.setRowChecked({})}
    {this.props.setDataList([])}
    {this.props.setOptionsStateValue([])}
    {this.props.setOptionsCountyValue([])}
    {this.props.setValue("")}
    {this.props.setSearchValues(this.props.initialSearchValues)}
    {this.props.setAllSelected(false)}
    if(this.props.selectAllRef.current){
        {this.props.selectAllRef.current.checked=false}
    }
    this.cancel();
    {this.props.showToast("Entries Deleted")}
}

cleanSingleValues() {
    {this.props.getDataWithAxios(this.props.baseURL)}
    this.cancel();
    {this.props.showToast("Entry Deleted")}
}

handleSubmit = (e) => {
    if(e){
        e.preventDefault();
    }
};



render() 

{
  return (
        <div>
        <div className = "card-body">
        <form onSubmit={this.handleSubmit} id="deleteForm">
            <Modal className={modalStyle.deleteModal} show={this.props.showDeleteModal}>
               <Modal.Body className={modalStyle.deleteModalBody}>
               <div className="p-6 text-center">
                    <div className={style.iconWrapper}>
                           <div style={{"marginLeft":"140px"}}>
                           <Icon path={mdiDeleteCircle} size={2} />
                           </div> 

                    </div>
                         {this.state.title1}
                        <br/>
                    {this.state.title2}                    
                   </div>

                   <div className={style.errorHandler}>
                        {this.state.message && (
                           <div className="flex">
                              <div className="grid grid-cols-7 grid-rows-1">    
                                    <div className="col-span-1">
                                        <i className="far fa-times-circle fa-2x "></i>
                                    </div>    
                                    <div className="col-span-6">
                                       {this.state.message}
                                    </div>    
                                    </div>    
                                    </div>
                    )}
                   </div>

               </Modal.Body> 
               <Modal.Footer className={modalStyle.deleteModalFooter}>
                    <div className="grid grid-cols-1">
                        <div className="col-span-1 py-2">
                        <span style={{"margin":"36px"}}></span>
                            <Button  className={modalStyle.deleteButtonPrimary} onClick={this.deleteGeneral.bind(this)} >Confirm</Button>
                            <span className="px-3"></span>
                            <Button className={modalStyle.buttonSecondary} onClick={this.cancel.bind(this)} >Cancel</Button>
                       </div>
                    </div>
                    
                </Modal.Footer>


            </Modal>
            </form>
        </div>

        <br></br>
            
        </div>
    )
    
}

}
export default DeleteModal;