import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'

import style from '../../css/layout.module.css';
import modalStyle from '../../css/globaltable.module.css';

import EditableTable from "../table/EditableTable";

import CountyService from "../services/CountyService";
import EntityService from "../services/EntityService";
import ClliService from "../services/ClliService";
import ExchangeService from "../services/ExchangeService";


class TableModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data:[],
            arraySaved:[],
            message:'',
            columns:[],
            rowsInput:{},
            columnMaxLength:{}
        }

        this.initialState = this.state;

        this.saveGeneral = this.saveGeneral.bind(this);
        this.saveCounties = this.saveCounties.bind(this);
        this.saveEntities = this.saveEntities.bind(this);
        this.saveCllis = this.saveCllis.bind(this);
        this.saveExchanges = this.saveExchanges.bind(this);

        this.createData = this.createData.bind(this);
        this.updateData = this.updateData.bind(this);
        this.handleNewRowClick = this.handleNewRowClick.bind(this);
        this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
        this.columnDefitition = this.columnDefitition.bind(this);

        this.updateResponse = this.updateResponse.bind(this);
    }
    
    componentDidMount(prevProps, prevState){
        let tableData = this.createData(this.props.preGlobalFilteredRows,this.props.dataList, this.props.setTableData);
        this.setState({data:tableData});

        {this.columnDefitition()}

    }

    cancel(){
        this.props.setShowEditTableModal(false);
        this.props.getDataWithAxios(this.props.baseURL);
    }

    saveGeneral =  (e) => {
        e.preventDefault();

        switch (this.props.entityType) {

            case "county":   
               this.saveCounties(e, this.state.data);
               return;

            case "entity":   
               this.saveEntities(e, this.state.data);
               return;

            case "clli":   
               this.saveCllis(e, this.state.data);
               return;

            case "exchange":   
               this.saveExchanges(e, this.state.data);
               return;


        }       

    }

    columnDefitition =  ()  => {

        let columns = [];
        let initialValues = {};
        let maxLength = {};

        switch (this.props.entityType) {

            case "county":  
                const countyHeaders = [
                  {
                    Header: 'County ID',
                    accessor: 'countyCd',
                    sortable:true,
                    required: true,
                    maxWidth: 200,
                    minWidth: 200,
                    width: 150,   
                  }
                ] 
                columns = entityHeaders(this.handleRemoveSpecificRow).concat(countyHeaders);

                this.setState({columns:columns});

                initialValues = {
                    actions:'',
                    idNum:'',
                    customerId:'',
                    stateCd:'',
                    countyName:'',
                    countyCd:''
                }      

                this.setState({rowsInput:initialValues});  

                maxLength["customerId"] = 20;
                maxLength["stateCd"] = 2;
                maxLength["countyName"] = 32;
                maxLength["countyCd"] = 4;

                this.setState({columnMaxLength:maxLength});
                return ;

          case "clli":   
              const clliHeaders = [
                {
                  Header: 'Community',
                  accessor: 'community',
                  sortable:true,
                  required: false,
                  maxWidth: 150,
                  minWidth: 150,
                  width: 150,   
              },
              {
                  Header: 'Entity',
                  accessor: 'entity',
                  sortable:true,
                  required: true,
                  maxWidth: 150,
                  minWidth: 150,
                  width: 150,   
              },
              {
                  Header: 'CLLI',
                  accessor: 'clliCd',
                  sortable:true,
                  required: true,
                  maxWidth: 150,
                  minWidth: 150,
                  width: 150,   
              }
                ] 

          columns = entityHeaders(this.handleRemoveSpecificRow).concat(clliHeaders);

          this.setState({columns:columns});

          initialValues = {
            actions:'',
            idNum:'',
            customerId:'',
            stateCd:'',
            countyName:'',
            clliCd:'',
            entity:'',
            community:''
          }       

          this.setState({rowsInput:initialValues});  

          maxLength["customerId"] = 20;
          maxLength["stateCd"] = 2;
          maxLength["countyName"] = 30;
          maxLength["countyCd"] = 4;
          maxLength["community"] = 32;
          maxLength["entity"] = 3;
          maxLength["clliCd"] = 11;

          this.setState({columnMaxLength:maxLength});
          return ;

          case "entity":   

           const entityTHeaders = [
               {
                Header: 'Community',
                accessor: 'community',
                sortable:true,
                required: false,
                maxWidth: 150,
                minWidth: 150,
                width: 150,   
                },
                {
                Header: 'Entity',
                accessor: 'entity',
                sortable:true,
                required: true,
                maxWidth: 150,
                minWidth: 150,
                width: 150,   
                }
            ]  

          columns = entityHeaders(this.handleRemoveSpecificRow).concat(entityTHeaders);
      
          this.setState({columns:columns});

          initialValues = {
            actions:'',
            idNum:'',
            customerId:'',
            stateCd:'',
            countyName:'',
            entity:'',
            community:''
          }       

          this.setState({rowsInput:initialValues});  

          maxLength["customerId"] = 20;
          maxLength["stateCd"] = 2;
          maxLength["countyName"] = 30;
          maxLength["community"] = 32;
          maxLength["entity"] = 3;

          this.setState({columnMaxLength:maxLength});
          return ;

          case "exchange":   

          const exchangeHeaders = [
            {
              Header: 'Community',
              accessor: 'community',
              sortable:true,
              required: false,
              maxWidth: 150,
              minWidth: 150,
              width: 150,   
            },
            {
              Header: 'Exchange',
              accessor: 'exchange',
              sortable:true,
              required: true,
              maxWidth: 150,
              minWidth: 150,
              width: 150,   
            }
          ]  

          columns = entityHeaders(this.handleRemoveSpecificRow).concat(exchangeHeaders);

          this.setState({columns:columns});

          initialValues = {
            actions:'',
            idNum:'',
            customerId:'',
            stateCd:'',
            countyName:'',
            exchange:'',
            community:''
          }       

          this.setState({rowsInput:initialValues});  

          maxLength["customerId"] = 20;
          maxLength["stateCd"] = 2;
          maxLength["countyName"] = 30;
          maxLength["community"] = 32;
          maxLength["exchange"] = 4;

          this.setState({columnMaxLength:maxLength});
          return;          
        }         
    }
  

    createData = (data,dataList, setTableData) => {

        let arrayData = [];
  
        data.map((row) => {
          let index = dataList.indexOf(row.values.idNum);
          if (index > -1) { 
              arrayData.push(row.values);
          }
        })
        return arrayData;
    };  
      

    updateData = (rowIndex, columnId, value) => {
        const newValues = this.state.data.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...this.state.data[rowIndex],
              [columnId]: (!value?row[columnId]:value)
            };
          }
          return row;
        })
        this.setState({data:newValues});
    };

    handleNewRowClick = () => {
        this.setState({data:[...this.state.data, this.state.rowsInput]});
        this.props.setButtonDisabled(false);
     };    
 
     handleRemoveSpecificRow = (index, props)  => 
     {
       const dataFilter= props.filter((item, i) => i != index);
       this.setState({data:dataFilter});
       this.props.setButtonDisabled(dataFilter.length===0);
     }  

     updateResponse(res, errorMessage, arraySaved){

        if(Array.isArray(res.data.body)){
            res.data.body.map((response) => {
                if(!response.idNum){
                    if(response.indexOf('ERROR')!== -1){
                        errorMessage=(`${errorMessage}\n${response}`);
                    }
                    else
                    {
                        this.setState({arraySaved:([this.state.arraySaved],response)});
                        arraySaved.add(response);
                    }
                }
             })
             this.setState({message:errorMessage});    
        }
        else{
            errorMessage = res.data.body.indexOf('ERROR')!== -1?errorMessage+ "\n" + res.data.body:"";
            this.setState({message:errorMessage});
        }

        return errorMessage;

     }

    saveCounties = (e, countyList) => {

        this.props.setErrorMessage("");
        let arrayCounty = [];
        const arraySaved = new Set([]);
        

        let errorMessage="";

        countyList.map((county) => {

            if(county.countyCd&&county.countyName&&county.stateCd&&county.customerId){

                let countyRow = {customerId: county.customerId,
                    countyCd:county.countyCd,
                    stateCd: county.stateCd,
                    countyName: county.countyName, 
                    createUser: "cmorrison" 
                };

                arrayCounty.push(countyRow); 
    
            }
            else{
                errorMessage=(`${errorMessage}\nERROR - ${county.countyCd} County does not have all the required values!`);
            }
        });

        CountyService.createCounty(arrayCounty)
        .then(res =>{
            
            errorMessage = this.updateResponse(res, errorMessage, arraySaved);
                        
            const arrayCounties = Array.from(arraySaved);

            
            const countyFilter = countyList.filter(el => {
                let found = false;

                for (let value of arrayCounties) {
                    if (value.toLowerCase().indexOf(el.countyCd.toLowerCase())!== -1) 
                    {
                        found=true;
                        break;
                    }
                }
                
                return !found;

              }   
            )

            this.setState({data:countyFilter});

            if((countyFilter.length===0 || arrayCounties.length===0) && errorMessage.length == 0){
                this.cancel();
                {this.props.getDataWithAxios(this.props.baseURL)}
                {this.props.showToast("New entries added")}
                
            }
        
        })
        .catch(err =>{
            this.setState({message:err.message});
        });

    };

    saveEntities = (e, entityList) => {

        e.preventDefault();

        this.props.setErrorMessage("");
        let arrayEntity = [];
        const arraySaved = new Set([]);

        let errorMessage="";

        entityList.map((entity) => {

            if(entity.entity&&entity.countyName&&entity.stateCd&&entity.customerId){

                let entityRow = {entity:entity.entity,
                    community:entity.community,
                    countyName: entity.countyName, 
                    customerId: entity.customerId,
                    stateCd: entity.stateCd,
                    createUser: "cmorrison" 
                };


                arrayEntity.push(entityRow); 
            }
            else{
                errorMessage=(`${errorMessage}\nERROR - ${entity.entity} Entity does not have all the required values!`);
            }


        })    


        EntityService.createEntity(arrayEntity)
        .then(res =>{
            errorMessage = this.updateResponse(res, errorMessage, arraySaved);
                        
            const arrayEntities = Array.from(arraySaved);
            
            const entityFilter = entityList.filter(el => {
                let found = false;

                for (let value of arrayEntities) {
                    if (value.toLowerCase().indexOf(el.entity.toLowerCase())!== -1) 
                    {
                        found=true;
                        break;
                    }
                }    

                return !found;

              }   
            )

            this.setState({data:entityFilter});
            
            if((entityFilter.length===0 || arrayEntities.length===0) && errorMessage.length == 0){
                this.cancel();
                {this.props.getDataWithAxios(this.props.baseURL)}
                {this.props.showToast("New entries added")}
            }
            
        })
        .catch(err =>{
            
            this.setState({message:err.message});
        });

    };

    saveCllis = (e, clliList) => {

        e.preventDefault();

        this.props.setErrorMessage("");
        let arrayClli = [];
        const arraySaved = new Set([]);

        let errorMessage="";


        clliList.map((clli) => {

            if(clli.clliCd&&clli.countyName&&clli.stateCd&&clli.customerId&&clli.entity){

                let clliRow = {clliCd:clli.clliCd,
                    community:clli.community,
                    countyName: clli.countyName, 
                    customerId: clli.customerId,
                    entity:clli.entity,
                    stateCd: clli.stateCd,
                    createUser: "cmorrison" 
                };

                arrayClli.push(clliRow); 
    
            }
            else{
                errorMessage=(`${errorMessage}\nERROR - ${clli.cllliCd} Clli does not have all the required values!`);
            }


        })    



        ClliService.createClli(arrayClli)
        .then(res =>{
            errorMessage = this.updateResponse(res, errorMessage, arraySaved);
                        
            const arrayCllis = Array.from(arraySaved);
            
            const clliFilter = clliList.filter(el => {
                let found = false;

                for (let value of arrayCllis) {
                    if (value.toLowerCase().indexOf(el.clliCd.toLowerCase())!== -1) 
                    {
                        found=true;
                        break;
                    }

                }    

                return !found;

              }   
            )

            this.setState({data:clliFilter});

            if((clliFilter.length===0 || arrayCllis.length===0) && errorMessage.length == 0){
                this.cancel();
                {this.props.getDataWithAxios(this.props.baseURL)}
                {this.props.showToast("New entries added")}
            }
            
        })
        .catch(err =>{
            
            this.setState({message:err.message});
        });

    };


    saveExchanges = (e, exchangeList) => {

        e.preventDefault();

        this.props.setErrorMessage("");
        let arrayExchange = [];
        const arraySaved = new Set([]);

        let errorMessage="";

        exchangeList.map((exchange) => {

            if(exchange.exchange&&exchange.countyName&&exchange.stateCd&&exchange.customerId){

                let exchangeRow = {exchange:exchange.exchange,
                    community:exchange.community,
                    countyName: exchange.countyName, 
                    customerId: exchange.customerId,
                    stateCd: exchange.stateCd,
                    createUser: "cmorrison" 
                };

                arrayExchange.push(exchangeRow); 
    
            }
            else{
                errorMessage=(`${errorMessage}\nERROR - ${exchange.exchange} Exchange does not have all the required values!`);
            }


        })    


        ExchangeService.createExchange(arrayExchange)
        .then(res =>{
            errorMessage = this.updateResponse(res, errorMessage, arraySaved);
                        
            const arrayExchanges = Array.from(arraySaved);
            
            const exchangeFilter = exchangeList.filter(el => {
                let found = false;

                for (let value of arrayExchanges) {
                    if (value.toLowerCase().indexOf(el.exchange.toLowerCase())!== -1) 
                    {
                        found=true;
                        break;
                    }

                }    
                return !found;

              }   
            )

            this.setState({data:exchangeFilter});

            if((exchangeFilter.length===0 || arrayExchanges.length===0) && errorMessage.length == 0){
                this.cancel();
                {this.props.getDataWithAxios(this.props.baseURL)}
                {this.props.showToast("New entries added")}
            }
            
        })
        .catch(err =>{
            
            this.setState({message:err.message});
        });

    };



    render(
    
      ) 

      {
        
  
        return (
            <div>
                   <div>
            <form onSubmit={this.handleSubmit} id="tableForm">
              
                <Modal 
                   scrollable={true}
                   dialogClassName={style.fullscreenmodal} show={this.props.showEditTableModal}>
                   <Modal.Header className={modalStyle.modalHeader}>
                        <Modal.Title className={modalStyle.modalTitle}>
                              <div className="flex px-2"  >
                               <span style={{ marginTop: '0.5rem' }}>
                                </span>
                              </div>
                        </Modal.Title>
                   </Modal.Header>  
                   <Modal.Body className={modalStyle.modalBody}>
                      <div  className = "form-group">
                       { 
                       <EditableTable
                        columns={this.state.columns}
                        data={this.state.data}
                        updateData={this.updateData}
                        initialState={this.props.initialState}
                        setErrorMessage={this.props.setErrorMessage}
                        errorMessage={this.props.errorMessage}
                        handleNewRowClick={this.handleNewRowClick}
                        handleRemoveSpecificRow={this.handleRemoveSpecificRow}
                        tableTitle={this.props.tableTitle}
                        setButtonDisabled={this.props.setButtonDisabled}
                        columnMaxLength={this.state.columnMaxLength}
                        />
                       }
                       
                       {this.state.message && (
                         <div style={{whiteSpace: "pre-line" ,margin: '-120px 35px'}}>
                              <span className={style.showError}>{this.state.message}</span>
                         </div>
                       )}


                      </div>

                   </Modal.Body> 
                   <Modal.Footer className={modalStyle.tableModalFooter}>
                        <div className="grid grid-cols-6">
                            <div className="col-span-6 px-10 py-2">
                                <Button  className={modalStyle.buttonPrimary} onClick={(e) => this.saveGeneral(e)}
                                   disabled={this.props.buttonDisabled}
                                   >Save All</Button>
                                <span className="px-4"></span>
                                <Button className={modalStyle.buttonSecondary} onClick={this.cancel.bind(this)} >Cancel</Button>
                           </div>
                        </div>
                        
                    </Modal.Footer>
                </Modal>
             </form>
       </div>
       <br></br>
       </div>
    )
    }      

    
    
}    

export function entityHeaders(handleRemoveSpecificRow) {

  return(
  [
    {
      Header: "",
      accessor: "actions",
      disableSortBy: true,
      Cell: (props) => {
        const rowIdx = props.row.id;
        return (
          <div>
                <span onClick={(e) => {
                  {handleRemoveSpecificRow(rowIdx,props.data)}}}                      
                  className={style.delTableIcon}>
                  <i className="text-gray-400 fas fa-trash action"></i>
                </span>

          </div>
        );
      },
      maxWidth: 40,
      minWidth: 40,
      width: 40,            
    },
    {
      Header: 'idNum',
      accessor: 'idNum',
      maxWidth: 5,
      minWidth: 5,
      width: 5,            
      sortable:true,
      },
      {
      Header: 'Customer Id',
      accessor: 'customerId',
      sortable:true,
      required: true,
      maxWidth: 150,
      minWidth: 150,
      width: 150,   
      },
      {
      Header: 'State',
      accessor: 'stateCd',
      sortable:true,
      required: true,
      maxWidth: 150,
      minWidth: 150,
      width: 150,   
      },
      {
      Header: 'County',
      accessor: 'countyName',
      sortable:true,
      required: true,
      maxWidth: 150,
      minWidth: 150,
      width: 150,   
      },
    ]
  )
    
}  

export default TableModal;