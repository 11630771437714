import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL + "/agency";

class AgencyService {

    createAgency(agency){
        return axios.post(baseURL + "/save", agency);
    }

    getAgencyById(agencyId){
        return axios.get(baseURL + '/search?idNum=' + agencyId);
    }    

    updateAgency(agency){
        return axios.post(baseURL, agency);
    }

    deleteAgency(agencyId){
        return axios.post(baseURL + '/delete?agencyId=' + agencyId);
    }

    
}

export default new AgencyService()