import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ShowModal from "../modal/ShowModal";
import DeleteModal from "../modal/DeleteModal";
import CreateModal from "../modal/CreateModal";
import TableModal from "../modal/TableModal";

const GlobalTableFilter = props => {

}

export function InitialTableValues(props,
    globalFilter,
    preGlobalFilteredRows,
    setShowModal,
    setShowAddModal,
    setShowEditModal,
    setShowDeleteModal,
    setDeleteType,
    setGlobalFilter,
    setCurrentData,
    filteredRows,
    preFilteredRows,
    selectInputCountyRef,
    selectInputStateRef,
    showModal,
    onChangeFilterModal,
    optionsStateValue,
    setOptionsStateValue,
    optionsCountyValue,
    setOptionsCountyValue,
    setStateFilters,
    setCountyFilters,
    setFilteredRows,
    headerGroups,
    showAddModal,
    getDataWithAxios,
    showToast,
    stateFilters,
    showEditModal,
    rowId,
    showDeleteModal,
    countyCd,
    deleteType,
    dataList,
    setDataList,
    setRowChecked,
    initialCheckValues,
    selectTableRef,
    rowChecked,
    elements,
    setElements,
    initialSearchValues,
    setSearchValues,
    setAllSelected,
    selectAllRef,
    showEditTableModal,
    setShowEditTableModal,
    onClickTableEditModal,
    currentTableData,
    entityType,
    tableTitle,
    searchValues,
    agencyId,
    focus,
    setFocus,
    key1Ref,
    key2Ref,
    key3Ref,
    key4Ref,
    key5Ref,
    key6Ref,
    globalSearchRef,
    agencyInput,
    onClickModal,
    onCloseModal,
    onCloseEditModal,
    onClickDeleteModal,
    onCloseDeleteModal,
    onClickAddModal,
    addType,
    setAddType,
    tableData,
    setTableData,
    clearSearch,
    clearGlobalSearch,
    currentData,
    setCommunityDirection,
    clliCd,
    entity,
    exchange,
    exchangeDirection,
    setExchangeDirection,
    setCurrentPage
) {


    const [filtersSelected, setFiltersSelected] = useState([]);
    const [optionsValue, setOptionsValue] = useState([]);
    const [value, setValue] = useState(globalFilter);
    const [errorMessage, setErrorMessage] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);


    const navigate = useNavigate();
    const count = preGlobalFilteredRows.length;

    const initialState = {
        hiddenColumns: ['idNum'],
        defaultCanSort: true,
        disableSortBy: false,
        manualSortBy: true,
        enableSortingRemoval: false,
    };

    const onChange = (value, globalSearchRef, setFocus, clearSearch) => {
        setGlobalFilter(value || undefined);
        setFocus(globalSearchRef);
        clearSearch();
    };


    const globalSearchValue = (value, e) => {
        return (
            value.values.customerId.toLowerCase().includes(e.toLowerCase()) ||
            value.values.stateCd.toLowerCase().includes(e.toLowerCase()) ||
            value.values.countyName.toLowerCase().includes(e.toLowerCase())
        )

    }
    const globalSearch = (e, entityType) => {

        const currentDataCopy = [...preGlobalFilteredRows];

        let filteredData = null;

        switch (entityType) {

            case "county":
                filteredData = currentDataCopy.filter(value => {
                    return (
                        globalSearchValue(value, e) ||
                        value.values.countyCd.toLowerCase().includes(e.toLowerCase())
                    );
                });
                setCurrentData(filteredData);
                return;

            case "clli":
                filteredData = currentDataCopy.filter(value => {
                    return (
                        globalSearchValue(value, e) ||
                        (value.values.community ? value.values.community.toLowerCase().includes(e.toLowerCase()) : "") ||
                        value.values.clliCd.toLowerCase().includes(e.toLowerCase())
                    );
                });
                setCurrentData(filteredData);
                return;

            case "entity":
                filteredData = currentDataCopy.filter(value => {
                    return (
                        globalSearchValue(value, e) ||
                        (value.values.community ? value.values.community.toLowerCase().includes(e.toLowerCase()) : "") ||
                        value.values.entity.toLowerCase().includes(e.toLowerCase())
                    );
                });
                setCurrentData(filteredData);
                return;

            case "exchange":

                filteredData = currentDataCopy.filter(value => {
                    return (
                        globalSearchValue(value, e) ||
                        (value.values.community ? value.values.community.toLowerCase().includes(e.toLowerCase()) : "") ||
                        value.values.exchange.toLowerCase().includes(e.toLowerCase())
                    );
                });
                setCurrentData(filteredData);

                return;

            case "agency":
                filteredData = currentDataCopy.filter(value => {
                    return (
                        value.values.agencyId.toLowerCase().includes(e.toLowerCase()) ||
                        value.values.customerId.toLowerCase().includes(e.toLowerCase()) ||
                        value.values.agencyName.toLowerCase().includes(e.toLowerCase()) ||
                        (value.values.inputMsagAddrPtFileLoc ? value.values.inputMsagAddrPtFileLoc.toLowerCase().includes(e.toLowerCase()) : "") ||
                        (value.values.outputMsagChgFileLoc ? value.values.outputMsagChgFileLoc.toLowerCase().includes(e.toLowerCase()) : "") ||
                        (value.values.outputLogFileLoc ? value.values.outputLogFileLoc.toLowerCase().includes(e.toLowerCase()) : "") ||
                        (value.values.outputFileArchiveLoc ? value.values.outputFileArchiveLoc.toLowerCase().includes(e.toLowerCase()) : "")
                    );
                });

                setCurrentData(filteredData);
                return;

        }

    };

    const globalSearchMulti = (e, entityType, searchValues, setCurrentData, setSearchValues, setCurrentPage) => {

        const currentDataCopy = [...preGlobalFilteredRows];

        let filteredData = null;

        setCurrentPage(1);
        switch (entityType) {
            case "county":
                filteredData = currentDataCopy.filter((value) => {

                    return value.values.countyCd.toLowerCase().indexOf(searchValues.countyCd ? searchValues.countyCd.toLowerCase() : "") > -1 &&
                        value.values.customerId.toLowerCase().indexOf(searchValues.customerId ? searchValues.customerId.toLowerCase() : "") > - 1 &&
                        value.values.stateCd.toLowerCase().indexOf(searchValues.stateCd ? searchValues.stateCd.toLowerCase() : "") > - 1 &&
                        value.values.countyName.toLowerCase().indexOf(searchValues.countyName ? searchValues.countyName.toLowerCase() : "") > - 1
                }

                );


                { setCurrentData(filteredData) }
                { setSearchValues(searchValues) }

                return;

            case "clli":

                filteredData = currentDataCopy.filter((value) => {
                    return value.values.customerId.toLowerCase().indexOf(searchValues.customerId ? searchValues.customerId.toLowerCase() : "") > - 1 &&
                        value.values.stateCd.toLowerCase().indexOf(searchValues.stateCd ? searchValues.stateCd.toLowerCase() : "") > - 1 &&
                        value.values.countyName.toLowerCase().indexOf(searchValues.countyName ? searchValues.countyName.toLowerCase() : "") > - 1 &&
                        value.values.community.toLowerCase().indexOf(searchValues.community ? searchValues.community.toLowerCase() : "") > -1 &&
                        value.values.entity.toLowerCase().indexOf(searchValues.entity ? searchValues.entity.toLowerCase() : "") > -1 &&
                        value.values.clliCd.toLowerCase().indexOf(searchValues.clliCd ? searchValues.clliCd.toLowerCase() : "") > -1
                });

                { setCurrentData(filteredData) }
                { setSearchValues(searchValues) }

                return;

            case "entity":
                filteredData = currentDataCopy.filter((value) => {
                    return value.values.customerId.toLowerCase().indexOf(searchValues.customerId ? searchValues.customerId.toLowerCase() : "") > - 1 &&
                        value.values.stateCd.toLowerCase().indexOf(searchValues.stateCd ? searchValues.stateCd.toLowerCase() : "") > - 1 &&
                        value.values.countyName.toLowerCase().indexOf(searchValues.countyName ? searchValues.countyName.toLowerCase() : "") > - 1 &&
                        value.values.community.toLowerCase().indexOf(searchValues.community ? searchValues.community.toLowerCase() : "") > -1 &&
                        value.values.entity.toLowerCase().indexOf(searchValues.entity ? searchValues.entity.toLowerCase() : "") > -1
                });

                { setCurrentData(filteredData) }
                { setSearchValues(searchValues) }

                return;

            case "exchange":

                filteredData = currentDataCopy.filter((value) => {
                    return value.values.customerId.toLowerCase().indexOf(searchValues.customerId ? searchValues.customerId.toLowerCase() : "") > - 1 &&
                        value.values.stateCd.toLowerCase().indexOf(searchValues.stateCd ? searchValues.stateCd.toLowerCase() : "") > - 1 &&
                        value.values.countyName.toLowerCase().indexOf(searchValues.countyName ? searchValues.countyName.toLowerCase() : "") > - 1 &&
                        value.values.community.toLowerCase().indexOf(searchValues.community ? searchValues.community.toLowerCase() : "") > -1 &&
                        value.values.exchange.toLowerCase().indexOf(searchValues.exchange ? searchValues.exchange.toLowerCase() : "") > -1
                });

                { setCurrentData(filteredData) }
                { setSearchValues(searchValues) }
                return;

            case "agency":

                filteredData = currentDataCopy.filter((value) => {
                    return value.values.agencyName.toLowerCase().indexOf(searchValues.agencyName ? searchValues.agencyName.toLowerCase() : "") > -1 &&
                        value.values.agencyId.toLowerCase().indexOf(searchValues.agencyId ? searchValues.agencyId.toLowerCase() : "") > -1 &&
                        value.values.customerId.toLowerCase().indexOf(searchValues.customerId ? searchValues.customerId.toLowerCase() : "") > - 1
                });

                { setCurrentData(filteredData) }
                { setSearchValues(searchValues) }
                return;


        }
    };


    return {
        filtersSelected,
        setFiltersSelected,
        optionsValue,
        setOptionsValue,
        count,
        value,
        setValue,
        navigate,
        setShowModal,
        setShowAddModal,
        setShowEditModal,
        setShowDeleteModal,
        setDeleteType,
        setGlobalFilter,
        onChange,
        preGlobalFilteredRows,
        setCurrentData,
        globalSearch,
        filteredRows,
        preFilteredRows,
        selectInputCountyRef,
        selectInputStateRef,
        showModal,
        onChangeFilterModal,
        optionsStateValue,
        setOptionsStateValue,
        optionsCountyValue,
        setOptionsCountyValue,
        setStateFilters,
        setCountyFilters,
        setFilteredRows,
        headerGroups,
        showAddModal,
        getDataWithAxios,
        showToast,
        stateFilters,
        showEditModal,
        rowId,
        showDeleteModal,
        countyCd,
        deleteType,
        dataList,
        setDataList,
        setRowChecked,
        initialCheckValues,
        selectTableRef,
        rowChecked,
        elements,
        setElements,
        initialSearchValues,
        setSearchValues,
        setAllSelected,
        selectAllRef,
        showEditTableModal,
        setShowEditTableModal,
        onClickTableEditModal,
        currentTableData,
        tableTitle,
        errorMessage,
        setErrorMessage,
        buttonDisabled,
        setButtonDisabled,
        initialState,
        entityType,
        onClickDeleteModal,
        setCommunityDirection,
        clliCd,
        searchValues,
        entity,
        exchange,
        exchangeDirection,
        setExchangeDirection,
        agencyId,
        focus,
        setFocus,
        key1Ref,
        key2Ref,
        key3Ref,
        key4Ref,
        key5Ref,
        key6Ref,
        globalSearchRef,
        agencyInput,
        onClickModal,
        onCloseModal,
        onCloseEditModal,
        onCloseDeleteModal,
        onClickAddModal,
        addType,
        setAddType,
        tableData,
        setTableData,
        clearSearch,
        clearGlobalSearch,
        globalSearchMulti,
        currentData,
        setCurrentPage
    }

}

export function ShowTableHeader(value,
    count,
    setValue,
    onChange,
    globalSearch,
    entityType,
    setFocus,
    globalSearchRef,
    clearSearch) {

    return (
        <div className="col-span-2 text-right">
            <input
                type="search"
                value={value || ""}
                ref={globalSearchRef}
                className="focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-slate-100 outline-none border-none h-9 text-gray-600"
                placeholder={entityType == "agency" ? ' Search Records' : `${count} unfiltered records...`}
                placeholdertextcolor="#000"
                aria-label="Search"
                aria-describedby="button-addon1"
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value, globalSearchRef, setFocus, clearSearch);
                    globalSearch(e.target.value, entityType);

                }}
            />
        </div>

    )
}



export function ShowModals(
    preFilteredRows,
    headerGroups,
    selectInputCountyRef,
    selectInputStateRef,
    showModal,
    onCloseModal,
    onChangeFilterModal,
    optionsValue,
    setOptionsValue,
    optionsStateValue,
    setOptionsStateValue,
    optionsCountyValue,
    setOptionsCountyValue,
    setStateFilters,
    setCountyFilters,
    filteredRows,
    setFilteredRows,
    setCurrentData,
    showAddModal,
    navigate,
    onCloseAddModal,
    getDataWithAxios,
    setShowAddModal,
    showToast,
    stateFilters,
    showEditModal,
    onCloseEditModal,
    rowId,
    showDeleteModal,
    onCloseDeleteModal,
    setShowDeleteModal,
    countyCd,
    deleteType,
    dataList,
    setDataList,
    setRowChecked,
    initialCheckValues,
    selectTableRef,
    rowChecked,
    elements,
    setElements,
    initialSearchValues,
    setSearchValues,
    setAllSelected,
    selectAllRef,
    setValue,
    showEditTableModal,
    setShowEditTableModal,
    currentTableData,
    tableTitle,
    preGlobalFilteredRows,
    tableData,
    initialState,
    setErrorMessage,
    errorMessage,
    setTableData,
    buttonDisabled,
    setButtonDisabled,
    entityType,
    entity,
    exchange,
    agencyId,
    baseURL,
    currentData
) {

    return (
        <>
            {preFilteredRows.length && showModal &&
                <ShowModal
                    filteredRows={filteredRows}
                    headerGroups={headerGroups}
                    selectInputCountyRef={selectInputCountyRef}
                    selectInputStateRef={selectInputStateRef}
                    showModal={showModal}
                    onCloseModal={onCloseModal}
                    onChangeFilterModal={onChangeFilterModal}
                    setOptionsValue={setOptionsValue}
                    optionsValue={optionsValue}
                    optionsStateValue={optionsStateValue}
                    setOptionsStateValue={setOptionsStateValue}
                    optionsCountyValue={optionsCountyValue}
                    setOptionsCountyValue={setOptionsCountyValue}
                    setStateFilters={setStateFilters}
                    setcountyFilters={setCountyFilters}
                    preFilteredRows={preFilteredRows}
                    setFilteredRows={setFilteredRows}
                    setCurrentData={setCurrentData}
                    currentTableData={currentTableData}
                    preGlobalFilteredRows={preGlobalFilteredRows} />
            }
            {showAddModal &&
                <CreateModal
                    addIdType={"_add"}
                    navigate={navigate}
                    showAddModal={showAddModal}
                    onCloseAddModal={onCloseAddModal}
                    getDataWithAxios={getDataWithAxios}
                    setShowAddModal={setShowAddModal}
                    showToast={showToast}
                    stateFilters={stateFilters}
                    filteredRows={preFilteredRows}
                    entityType={entityType}
                    initialSearchValues={initialSearchValues}
                    setSearchValues={setSearchValues}
                    setOptionsStateValue={setOptionsStateValue}
                    setOptionsCountyValue={setOptionsCountyValue}
                    onCloseEditModal={onCloseEditModal}
                    baseURL={baseURL}
                />
            }
            {showEditModal &&
                <CreateModal
                    editIdType={"_edit"}
                    navigate={navigate}
                    showEditModal={showEditModal}
                    onCloseEditModal={onCloseEditModal}
                    getDataWithAxios={getDataWithAxios}
                    rowId={rowId}
                    showToast={showToast}
                    entityType={entityType}
                    initialSearchValues={initialSearchValues}
                    setSearchValues={setSearchValues}
                    setOptionsStateValue={setOptionsStateValue}
                    setOptionsCountyValue={setOptionsCountyValue}
                    baseURL={baseURL}
                    currentData={currentData}
                />
            }
            {showDeleteModal &&
                <DeleteModal
                    navigate={navigate}
                    showDeleteModal={showDeleteModal}
                    onCloseDeleteModal={onCloseDeleteModal}
                    getDataWithAxios={getDataWithAxios}
                    setShowDeleteCountyModal={setShowDeleteModal}
                    countyCd={countyCd}
                    rowId={rowId}
                    showToast={showToast}
                    deleteType={entityType == "agency" ? "single" : deleteType}
                    dataList={dataList}
                    setDataList={setDataList}
                    setRowChecked={setRowChecked}
                    initialCheckValues={initialCheckValues}
                    rowChecked={rowChecked}
                    elements={elements}
                    setElements={setElements}
                    optionsStateValue={optionsStateValue}
                    optionsCountyValue={optionsCountyValue}
                    setOptionsStateValue={setOptionsStateValue}
                    setOptionsCountyValue={setOptionsCountyValue}
                    setValue={setValue}
                    setSearchValues={setSearchValues}
                    initialSearchValues={initialSearchValues}
                    setAllSelected={setAllSelected}
                    selectAllRef={selectAllRef}
                    entityType={entityType}
                    selectTableRef={selectTableRef}
                    entity={entity}
                    exchange={exchange}
                    agencyId={agencyId}
                    baseURL={baseURL}
                />

            }
            {showEditTableModal &&
                <TableModal
                    navigate={navigate}
                    showEditTableModal={showEditTableModal}
                    setShowEditTableModal={setShowEditTableModal}
                    data={tableData}
                    initialState={initialState}
                    currentTableData={currentTableData}
                    dataList={dataList}
                    setErrorMessage={setErrorMessage}
                    errorMessage={errorMessage}
                    tableTitle={tableTitle}
                    setTableData={setTableData}
                    buttonDisabled={buttonDisabled}
                    setButtonDisabled={setButtonDisabled}
                    getDataWithAxios={getDataWithAxios}
                    showToast={showToast}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    entityType={entityType}
                    tableData={tableData}
                    baseURL={baseURL}
                />
            }
        </>
    )

}




export default GlobalTableFilter;