import React, { useEffect, useState, useRef } from 'react';
import style from '../../css/layout.module.css';
import axios from "axios";
import { SelectColumnFilterModal } from "../../components/modal/ShowModal";
import "@fortawesome/fontawesome-free/css/all.css";
import Translations, * as entityTranslations from '../../components/views/Translations.js';
import { BasicTable } from "../table/GlobalTable";
import { NotificationType, openNotificationWithIcon } from '../../components/notifications/notificationsBox.js';

const GeneralView = props => {

  let baseURL = "";
  let initialSearchValues = {};
  let entityType = "";
  let tableTitle = "";

  let viewProps = null;
  let entityRef = useRef();

  const { entityViewType } = props;

  const [countyCd, setCountyCd] = useState('');
  const [customerDirection, setCustomerDirection] = useState(0);
  const [stateCdDirection, setStateCdDirection] = useState(0);
  const [countyNameDirection, setCountyNameDirection] = useState(0);
  const [communityDirection, setCommunityDirection] = useState(0);
  const [entityDirection, setEntityDirection] = useState(0);
  const [clliCdDirection, setClliCdDirection] = useState(0);
  const [exchangeDirection, setExchangeDirection] = useState(0);
  const [agencyDirection, setAgencyDirection] = useState(0);


  useEffect(() => {
    if (entityViewType == "county") {
      baseURL = process.env.REACT_APP_BASE_URL + "/counties/all";
      setCustomerDirection(1);
      setStateCdDirection(1);
      setCountyNameDirection(-1)

    }
    if (entityViewType == "clli") {
      baseURL = process.env.REACT_APP_BASE_URL + "/cllis/all";
      setCustomerDirection(1);
      setStateCdDirection(1);
      setCountyNameDirection(1);
      setCommunityDirection(1);
      setEntityDirection(1);
      setClliCdDirection(-1)
    }
    if (entityViewType == "entity") {
      baseURL = process.env.REACT_APP_BASE_URL + "/entities/all";
      setCustomerDirection(1);
      setStateCdDirection(1);
      setCountyNameDirection(1);
      setCommunityDirection(1);
      setEntityDirection(-1);
    }
    if (entityViewType == "exchange") {
      baseURL = process.env.REACT_APP_BASE_URL + "/exchanges/all";
      setCustomerDirection(1);
      setStateCdDirection(1);
      setCountyNameDirection(1);
      setCommunityDirection(1);
      setExchangeDirection(-1);
    }
    if (entityViewType == "agency") {
      baseURL = process.env.REACT_APP_BASE_URL + "/agencies/all";
      setAgencyDirection(1);
      setCustomerDirection(1);
    }
  },
    []
  );


  viewProps = entityTranslations.InitialValues(initialSearchValues, baseURL);

  let otherProps = columnSet(entityViewType, viewProps, baseURL);

  viewProps.entityType = otherProps.entityType;
  viewProps.initialSearchValues = otherProps.initialSearchValues;
  viewProps.tableTitle = otherProps.tableTitle;
  viewProps.columnSortBy = otherProps.columnSortBy;
  viewProps.baseURL = otherProps.baseURL;
  viewProps.columns = otherProps.columns;

  useEffect(() => {

    const getAxiosData = async () => {

      await axios.get(viewProps.baseURL,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
          }
        }).then(response => { viewProps.setData(response.data) })
        .catch(err => {
          if (viewProps.isNetworkError(err)) {
            openNotificationWithIcon(NotificationType.ERROR, 'The connection attempt failed', process.env.REACT_APP_BASE_URL);
          }
          else {
            openNotificationWithIcon(NotificationType.ERROR, 'Error', err.message);
          }
        })

      entityRef.current = true


    }
    getAxiosData();
  },
    [entityViewType]);


  if (!entityRef.current) return null;

  return (

    <div className="md:container md:mx-0 my-8">
      <Translations {...viewProps} />
      <BasicTable
        {...viewProps}
        columns={viewProps.columns}
        data={viewProps.data}
        getDataWithAxios={viewProps.getDataWithAxios}
        setShowEditModal={viewProps.setShowEditModal}
        showEditModal={viewProps.showEditModal}
        onClickEditModal={viewProps.onClickEditModal}
        rowId={viewProps.rowId}
        showAddModal={viewProps.showAddModal}
        setShowAddModal={viewProps.setShowAddModal}
        showDeleteModal={viewProps.showDeleteModal}
        setShowDeleteModal={viewProps.setShowDeleteModal}
        onClickDeleteModal={viewProps.onClickDeleteModal}
        countyCd={countyCd}
        sorting={viewProps.sorting}
        setSorting={viewProps.setSorting}
        PageSize={viewProps.PageSize}
        currentPage={viewProps.currentPage}
        setCurrentPage={viewProps.setCurrentPage}
        initialState={viewProps.initialState}
        customerDirection={customerDirection}
        setCustomerDirection={setCustomerDirection}
        stateCdDirection={stateCdDirection}
        setStateCdDirection={setStateCdDirection}
        countyNameDirection={countyNameDirection}
        setCountyNameDirection={setCountyNameDirection}
        setPageSize={viewProps.setPageSize}
        searchValues={viewProps.searchValues}
        setSearchValues={viewProps.setSearchValues}
        initialSearchValues={initialSearchValues}
        dataList={viewProps.dataList}
        deleteType={viewProps.deleteType}
        setDeleteType={viewProps.setDeleteType}
        setDataList={viewProps.setDataList}
        setRowChecked={viewProps.setRowChecked}
        initialCheckValues={viewProps.initialCheckValues}
        selectTableRef={viewProps.selectTableRef}
        rowChecked={viewProps.rowChecked}
        elements={viewProps.elements}
        setElements={viewProps.setElements}
        setShowEditTableModal={viewProps.setShowEditTableModal}
        showEditTableModal={viewProps.showEditTableModal}
        onClickTableEditModal={viewProps.onClickTableEditModal}
        showImage={viewProps.showImage}
        optionsStateValue={viewProps.optionsStateValue}
        optionsCountyValue={viewProps.optionsCountyValue}
        setOptionsStateValue={viewProps.setOptionsStateValue}
        setOptionsCountyValue={viewProps.setOptionsCountyValue}
        selectInputCountyRef={viewProps.selectInputCountyRef}
        selectInputStateRef={viewProps.selectInputStateRef}
        setAllSelected={viewProps.setAllSelected}
        selectAllRef={viewProps.selectAllRef}
        columnSortBy={viewProps.columnSortBy}
        entityType={entityType}
        tableTitle={tableTitle}
        onClickModal={viewProps.onClickModal}
        onCloseModal={viewProps.onCloseModal}
        showModal={viewProps.showModal}
        setShowModal={viewProps.setShowModal}
        onCloseEditModal={viewProps.onCloseEditModal}
        onCloseDeleteModal={viewProps.onCloseDeleteModal}
        onClickAddModal={viewProps.onClickAddModal}
        addType={viewProps.addType}
        setAddType={viewProps.setAddType}
        tableData={viewProps.tableData}
        setTableData={viewProps.setTableData}
        communityDirection={communityDirection}
        setCommunityDirection={setCommunityDirection}
        entityDirection={entityDirection}
        setEntityDirection={setEntityDirection}
        clliCdDirection={clliCdDirection}
        setClliCdDirection={setClliCdDirection}
        clliCd={viewProps.clliCd}
        baseURL={viewProps.baseURL}
        exchangeDirection={exchangeDirection}
        setExchangeDirection={setExchangeDirection}
        agencyDirection={agencyDirection}
        setAgencyDirection={setAgencyDirection}
      />
    </div>
  );


  function columnSet(entityViewType, viewProps, baseURL) {

    let columns = [];
    let columnSortBy = [];

    if (entityViewType == "county") {
      baseURL = process.env.REACT_APP_BASE_URL + "/counties/all";
      entityRef = useRef();

      initialSearchValues = {
        countyCd: '',
        customerId: '',
        stateCd: '',
        countyName: '',
      }

      entityType = "county";
      tableTitle = "County Translation";

      columnSortBy = sortHeaders();

      columns = React.useMemo(
        () =>
          entityHeaders(viewProps).concat([
            {
              Header: 'County ID',
              accessor: 'countyCd',
              maxWidth: 5,
              minWidth: 5,
              width: 50,
            }])
        ,
        [entityViewType]
      );
    }


    if (entityViewType == "clli") {
      baseURL = process.env.REACT_APP_BASE_URL + "/cllis/all";
      entityRef = useRef();
      initialSearchValues = {
        customerId: '',
        stateCd: '',
        countyName: '',
        community: '',
        entity: '',
        clliCd: ''
      }

      entityType = "clli";
      tableTitle = "CLLI Translation";

      columnSortBy = sortHeaders().concat([
        {
          prop: 'community',
          direction: 1
        },
        {
          prop: 'entity',
          direction: 1
        },
        {
          prop: 'clliCd',
          direction: 1
        }
      ]);

      columns = React.useMemo(
        () =>
          entityHeaders(viewProps).concat([
            {
              Header: 'Community',
              accessor: 'community',
              multiSort: true,
              sortable: true,
              width: 50
            },
            {
              Header: 'Entity',
              accessor: 'entity',
              multiSort: true,
              sortable: true,
              width: 50
            },
            {
              Header: 'CLLI',
              accessor: 'clliCd',
              width: 50
            }
          ]
          )
        ,
        [entityViewType]
      );
    }

    if (entityViewType == "entity") {
      baseURL = process.env.REACT_APP_BASE_URL + "/entities/all";
      entityRef = useRef();

      initialSearchValues = {
        entity: '',
        community: '',
        countyName: '',
        stateCd: '',
        customerId: ''
      }

      entityType = "entity";
      tableTitle = "Entity Translation";

      columnSortBy = sortHeaders().concat([
        {
          prop: 'community',
          direction: 1
        },
        {
          prop: 'entity',
          direction: -1
        }
      ]);

      columns = React.useMemo(
        () =>
          entityHeaders(viewProps).concat([
            {
              Header: 'Community',
              accessor: 'community',
              multiSort: true,
              sortable: true,
              width: 50
            },
            {
              Header: 'Entity',
              accessor: 'entity',
              width: 50
            }
          ]),
        [entityViewType]
      );

    }

    if (entityViewType == "exchange") {
      baseURL = process.env.REACT_APP_BASE_URL + "/exchanges/all";
      entityRef = useRef();

      initialSearchValues = {
        community: '',
        countyName: '',
        stateCd: '',
        customerId: '',
        exchange: ''
      }

      entityType = "exchange";
      tableTitle = "Exchange Translation";

      columnSortBy = sortHeaders().concat([
        {
          prop: 'community',
          direction: 1
        },
        {
          prop: 'Exchange',
          direction: -1
        }
      ]);

      columns = React.useMemo(
        () =>
          entityHeaders(viewProps).concat([
            {
              Header: 'Community',
              accessor: 'community',
              multiSort: true,
              sortable: true,
              width: 50
            },
            {
              Header: 'Exchange',
              accessor: 'exchange',
              width: 50
            }
          ]
          )
        ,
        [entityViewType]
      );

    }
    if (entityViewType == "agency") {
      baseURL = process.env.REACT_APP_BASE_URL + "/agencies/all";
      entityRef = useRef();

      initialSearchValues = {
        agencyId: '',
        agencyName: '',
        customerId: ''
      }

      entityType = "agency";
      tableTitle = "Agency Maintenance";

      columnSortBy = [
        {
          prop: 'agencyId',
          direction: 1
        },
        {
          prop: 'customerId',
          direction: 1
        }
      ];

      columns = React.useMemo(
        () => [
          {
            Header: "Actions",
            accessor: "actions",
            disableSortBy: true,
            Cell: (cellProps) => {
              const rowIdx = cellProps.row.id;
              return (
                <div>
                  <span onClick={() => {
                    { viewProps.onClickEditModal(cellProps.row.values.idNum) }
                  }}
                    className={style.delIcon}>
                    <i className="text-gray-400 fa-solid fa-pen action mr-2"></i>
                  </span>

                  <span onClick={() => {

                    { viewProps.onClickDeleteModal(cellProps.row.values.idNum, "single", cellProps.row.values.agencyId) }
                  }}
                    className={style.delIcon}>
                    <i className="text-gray-400 fas fa-trash action"></i>
                  </span>
                </div>
              );
            },
            maxWidth: 40,
            minWidth: 40,
            width: 40,
          },
          {
            Header: 'idNum',
            accessor: 'idNum',
            width: 50
          },
          {
            Header: 'Agency Id',
            accessor: 'agencyId',
            maxWidth: 80,
            minWidth: 50,
            width: 50,
            filter: 'includes',
          },
          {
            Header: 'Customer Id',
            accessor: 'customerId',
            maxWidth: 80,
            minWidth: 80,
            width: 80,
          },
          {
            Header: 'Agency Name',
            accessor: 'agencyName',
            maxWidth: 90,
            minWidth: 80,
            width: 80,
          },
          {
            Header: 'MSAG Change File Input Location',
            accessor: 'inputMsagAddrPtFileLoc',
            maxWidth: 180,
            minWidth: 50,
            width: 50,
          },
          {
            Header: 'MSAG Change File Output Location',
            accessor: 'outputMsagChgFileLoc',
            maxWidth: 180,
            minWidth: 50,
            width: 50,

          },
          {
            Header: 'Log Location',
            accessor: 'outputLogFileLoc',
            maxWidth: 180,
            minWidth: 50,
            width: 50,
          },
          {
            Header: 'File Archive Location',
            accessor: 'outputFileArchiveLoc',
            maxWidth: 180,
            minWidth: 50,
            width: 50,
          },
          {
            Header: 'Consolidate Street Ranges',
            accessor: 'consolidateStreetRanges',
            maxWidth: 240,
            minWidth: 200,
            width: 200,
          }

        ],
        [entityViewType]
      );

    }


    return {
      entityType,
      initialSearchValues,
      tableTitle,
      columnSortBy,
      baseURL,
      entityRef,
      columns
    }


  }
}

function sortHeaders() {
  return ([
    {
      prop: 'customerId',
      direction: 1
    },
    {
      prop: 'stateCd',
      direction: 1
    },
    {
      prop: 'countyName',
      direction: 1
    },
  ]
  )

}
function entityHeaders(viewProps) {
  return (
    [
      {
        Header: (headerProps) => (

          <div className={style.checkboxwrapper}>
            <span>
              <input
                type="checkbox"
                ref={viewProps.selectAllRef}
                checked={viewProps.allSelected ? viewProps.allSelected : null}
                onChange={(e) => viewProps.handleOnChangeAll(headerProps, e)}
              />
              <i>Actions</i>
            </span>
          </div>
        ),
        accessor: "actions",
        disableSortBy: true,
        width: 50,
        minWidth: 50,
        Cell: (cellProps) => {
          const idNumValue = cellProps.row.values.idNum;
          return (
            <div className={style.checkboxwrapper}>
              <span>
                <input
                  type="checkbox"
                  id={"checkbox" + cellProps.row.id}
                  value={cellProps.row.id}
                  name="elements"
                  checked={viewProps.elements.includes(idNumValue)}
                  onChange={(e) => viewProps.handleOnChange(e, cellProps.row.values.idNum, null)}
                  unchecked="true"
                />
              </span>
              <span onClick={() => {
                { viewProps.onClickEditModal(cellProps.row.values.idNum) }
              }}
                className={style.delIcon}>
                <i className="text-gray-400 fa-solid fa-pen action mr-2"></i>
              </span>
              <span onClick={() => {
                { viewProps.onClickDeleteModal(cellProps.row.values.idNum, "single") }
              }}
                className={style.delIcon}>
                <i className="text-gray-400 fas fa-trash action"></i>
              </span>
            </div>
          );
        },
      },
      {
        Header: 'idNum',
        accessor: 'idNum',
        maxWidth: 5,
        minWidth: 5,
        width: 5,
      },
      {
        Header: 'Customer Id',
        accessor: 'customerId',
        multiSort: true,
        maxWidth: 5,
        minWidth: 5,
        width: 50,
        selector: "id",
      },
      {
        Header: 'State',
        accessor: 'stateCd',
        Filter: SelectColumnFilterModal,
        filter: 'includes',
        multiSort: true,
        sortable: true,
        maxWidth: 5,
        minWidth: 5,
        width: 50,
        headerStyle: (selector, id) => {
          return { textAlign: "left" };
        },
      },
      {
        Header: 'County',
        accessor: 'countyName',
        Filter: SelectColumnFilterModal,
        filter: 'includes',
        multiSort: true,
        sortable: true,
        maxWidth: 5,
        minWidth: 5,
        width: 50,

      },
    ]
  )
}
export default GeneralView;