import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useTable, useFilters, useGlobalFilter, usePagination, useSortBy } from "react-table"
import Pagination from './Pagination';
import style from '../../css/layout.module.css';
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Toast from "./Toast";
import TableGeneralSearch from './TableGeneralSearch';
import flist from '../../images/filter-list.svg';

import GlobalTableFilter, * as GlobalFilterTranslations from "./GlobalTableFilter";

const GlobalTable = props => {

  useEffect(() => {
    props.setCurrentData(props.dataNew);
    props.setCurrentPage(1);
    props.clearGlobalSearch();
    props.clearSearch();
  },
    [props.setCurrentData, props.dataNew]);

  useEffect(() => {
    props.setPageSize(props.PageSize);
  },
    [props.PageSize, props.dataNew]);

  useEffect(() => {
    props.setFocus(props.focus);
  },
    [props.focus, props.setFocus]);

  useEffect(() => {
    props.setSearchValues(props.searchValues);
  },
    [props.searchValues, props.setSearchValues]);

  useEffect(() => {
    props.selectPosition("bottom-right");
    props.setCheckValue(true);
    props.setAutoDeleteTime(parseInt(5000, 10));
    props.applySorting(null, true, "customerId");
    props.applySorting("customerId", true, "stateCd");
    props.applySorting("customerId", true, "countyNmae");
  }, []);



  useEffect(() => {
    let sort = null;

    switch (props.entityType) {
      case "county":
        sort = sortCountyTable(props.sortBy,
          props.sorting,
          props.setCustomerDirection,
          props.setStateCdDirection,
          props.setCountyNameDirection,
          props.customerDirection,
          props.stateCdDirection,
          props.countyNameDirection);
        { props.setSortBy(sort.sortBy) }
        { props.setCustomerDirection(sort.setCustomerDirection) }
        { props.setStateCdDirection(sort.setStateCdDirection) }
        { props.setCountyNameDirection(sort.setCountyNameDirection) }
        return;

      case "clli":
        sort = sortClliTable(props.sortBy,
          props.sorting,
          props.setCustomerDirection,
          props.setStateCdDirection,
          props.setCountyNameDirection,
          props.customerDirection,
          props.stateCdDirection,
          props.countyNameDirection,
          props.communityDirection,
          props.entityDirection,
          props.clliCdDirection,
          props.setCommunityDirection,
          props.setClliCdDirection,
          props.setEntityDirection);
        { props.setSortBy(sort.sortBy) }
        { props.setCustomerDirection(sort.setCustomerDirection) }
        { props.setStateCdDirection(sort.setStateCdDirection) }
        { props.setCountyNameDirection(sort.setCountyNameDirection) }
        { props.setCommunityDirection(sort.setCommunityDirection) }
        { props.setClliCdDirection(sort.setClliCdDirection) }
        { props.setEntityDirection(sort.setEntityDirection) }
        return;

      case "entity":
        sort = sortEntityTable(props.sortBy,
          props.sorting,
          props.setCustomerDirection,
          props.setStateCdDirection,
          props.setCountyNameDirection,
          props.customerDirection,
          props.stateCdDirection,
          props.countyNameDirection,
          props.communityDirection,
          props.entityDirection,
          props.setCommunityDirection,
          props.setEntityDirection);
        { props.setSortBy(sort.sortBy) }
        { props.setCustomerDirection(sort.setCustomerDirection) }
        { props.setStateCdDirection(sort.setStateCdDirection) }
        { props.setCountyNameDirection(sort.setCountyNameDirection) }
        { props.setCommunityDirection(sort.setCommunityDirection) }
        { props.setEntityDirection(sort.setEntityDirection) }
        return;

      case "exchange":
        sort = sortExchangeTable(props.sortBy,
          props.sorting,
          props.setCustomerDirection,
          props.setStateCdDirection,
          props.setCountyNameDirection,
          props.customerDirection,
          props.stateCdDirection,
          props.countyNameDirection,
          props.communityDirection,
          props.exchangeDirection,
          props.setCommunityDirection,
          props.setExchangeDirection)

        { props.setSortBy(sort.sortBy) }
        { props.setCustomerDirection(sort.setCustomerDirection) }
        { props.setStateCdDirection(sort.setStateCdDirection) }
        { props.setCountyNameDirection(sort.setCountyNameDirection) }
        { props.setCommunityDirection(sort.setCommunityDirection) }
        { props.setExchangeDirection(sort.setExchangeDirection) }
        return;

      case "agency":
        sort = sortAgencyTable(props.sortBy,
          props.sorting,
          props.setCustomerDirection,
          props.customerDirection,
          props.agencyDirection,
          props.setAgencyDirection)
        { props.setSortBy(sort.sortBy) }
        { props.setCustomerDirection(sort.setCustomerDirection) }
        { props.setAgencyDirection(sort.setAgencyDirection) }
        return;

    }


  }, [props.sorting]);

  useEffect(() => {
    const currentDataCopy = [...props.currentData];

    const sortedCurrentData = currentDataCopy.sort(

      function (a, b) {
        let i = 0, result = 0;
        while (i < props.sortBy.length && result === 0) {
          if (a.values[props.sortBy[i].prop] && b.values[props.sortBy[i].prop]) {
            result = props.sortBy[i].direction * (a.values[props.sortBy[i].prop].toString() < b.values[props.sortBy[i].prop].toString()
              ? -1
              : (a.values[props.sortBy[i].prop].toString() >
                b.values[props.sortBy[i].prop].toString()
                ? 1
                : 0));
          }
          else {
            result = "";
          }
          i++;
        }
        return result;
      }

    );

    props.setCurrentData(
      sortedCurrentData
    );
  }, [props.sortBy, props.setSortBy, props.sorting]);


}

export function InitialTableValues(props,
  columns,
  data,
  initialState,
  sorting,
  setSorting,
  currentPage,
  setCurrentPage,
  setSearchValues,
  initialSearchValues,
  PageSize,
  setPageSize,
  searchValues,
  dataList,
  setDataList,
  showImage,
  onClickDeleteModal,
  setElements,
  columnSortBy,
  entityType,
  setCustomerDirection,
  setStateCdDirection,
  setCountyNameDirection,
  customerDirection,
  stateCdDirection,
  countyNameDirection,
  communityDirection,
  setCommunityDirection,
  entityDirection,
  setEntityDirection,
  clliCdDirection,
  setClliCdDirection,
  exchangeDirection,
  setExchangeDirection,
  agencyDirection,
  setAgencyDirection,
  agencyId,
  addType,
  setAddType,
  tableData,
  setTableData,
) {



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    preFilteredRows,
    rows,
    page,
    state,
    state: { pageSize },
    rows: dataNew,
  } = useTable({
    columns, data, initialState: initialState,
  },
    useFilters, useGlobalFilter, useSortBy, usePagination);

  const [filteredRows, setFilteredRows] = useState([]);
  const [stateFilters, setStateFilters] = useState([]);
  const [countyFilters, setCountyFilters] = useState([]);
  const [firstPageRows, setFirstPageRows] = useState([rows]);
  const [showModal, setShowModal] = useState(false);
  const [list, setList] = useState([]);
  const [position, setPosition] = useState("");
  const [checkValue, setCheckValue] = useState(true);
  const [autoDeleteTime, setAutoDeleteTime] = useState(0);
  const [currentData, setCurrentData] = useState(dataNew);
  const [sortBy, setSortBy] = useState(columnSortBy);
  const [focus, setFocus] = useState("");

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return currentData.slice(firstPageIndex, lastPageIndex);

  },
    [currentPage, PageSize, dataNew, currentData]);


  const styles = {
    modal: { "margin-top": "25rem" },
  };


  const key1Ref = useRef();
  const key2Ref = useRef();
  const key3Ref = useRef();
  const key4Ref = useRef();
  const key5Ref = useRef();
  const key6Ref = useRef();

  const globalSearchRef = useRef();

  const clearSearch = event => {
    setSearchValues({});
  };

  const clearGlobalSearch = event => {
    setGlobalFilter("");
  };

  function applySorting(key, ascending, id, headers) {
    setSorting({ key: id, ascending: ascending });
  }

  const onChangeFilterModal = (stateFilters, countyFilters) => {

    let dataOrig = [];

    {
      headerGroups.map((headerGroup) =>

        headerGroup.headers.map((column) =>
          column.Filter
            ?
            (column.id == "stateCd"
              ?
              (
                data = preFilteredRows.filter((item) => {
                  if (Object.keys(stateFilters).length > 0) {
                    for (let key in stateFilters) {
                      if (item.values[key] === undefined || !stateFilters[key].includes(item.values[key])) {
                        return false;
                      }
                    }
                  }
                  return true;
                }),
                data.length > 0
                  ? (
                    (!countyFilters && !countyFilters.countyName && !countyFilters.countyName.length > 0)
                      ? null
                      : (
                        (countyFilters.countyName && countyFilters.countyName.length > 0
                          ? dataOrig = data.filter((item) => {
                            if (Object.keys(countyFilters).length > 0) {
                              for (let key in countyFilters) {
                                if (item.values[key] === undefined || !countyFilters[key].includes(item.values[key])) {
                                  return false;
                                }
                              }
                            }
                            return true;
                          })
                          : null),
                        (dataOrig.length > 0 ? setCurrentData(dataOrig) : setCurrentData(data))
                      )
                  )
                  : (
                    (countyFilters.countyName && countyFilters.countyName.length > 0
                      ? (data = preFilteredRows.filter((item) => {
                        if (Object.keys(countyFilters).length > 0) {
                          for (let key in countyFilters) {
                            if (item.values[key] === undefined || !countyFilters[key].includes(item.values[key])) {
                              return false;
                            }
                          }
                        }
                        return true;
                      }),
                        data.length > 0
                          ? (setCurrentData(data))
                          : null
                      )
                      : null
                    ),
                    (
                      !stateFilters.stateCd.length > 0 && !countyFilters.countyName.length > 0
                        ? setCurrentData(preFilteredRows)
                        : null
                    )
                  )
              )
              : null
            )
            : null
        )
      )
    }

    setShowModal(false);
  }

  const selectPosition = (value) => {
    setPosition(value);
    setList([]);
  }

  const showToast = toastTitle => {
    const TOAST_PROPERTIES = [
      {
        id: 'toast',
        title: toastTitle,
        description: toastTitle,
        backgroundColor: '#DCF4E3',
        icon: ''
      }
    ];

    const toastProperties = TOAST_PROPERTIES;
    setList([toastProperties]);

  }

  const isBoolean = (n) => {
    return !!n === n;
  }

  const GrayTooltip = withStyles({
    tooltip: {
      color: "#585858",
      backgroundColor: "#F5F5F5"
    }
  })(Tooltip);


  const onPagination = (currentPage, rows, PageSize, page, currentTable, setPageSize) => {

    if (PageSize === rows.length) {
      setCurrentPage(1);
    }
    return (
      <>
        <div className={style.pageMessage}>
          <Pagination
            currentPage={currentPage}
            totalCount={rows.length}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
            currentTable={currentTable}
            setPageSize={setPageSize}
            onPagination={onPagination}
            setCurrentPage={setCurrentPage}
            setSearchValues={setSearchValues}
            initialSearchValues={initialSearchValues}
          />


        </div>
      </>

    )
  }

  const deleteBatch = (dataList, entityType) => {

    { onClickDeleteModal("", "batch", dataList) }
    { setElements([[]]) }
  }


  return {
    filteredRows,
    setFilteredRows,
    stateFilters,
    setStateFilters,
    countyFilters,
    setCountyFilters,
    showModal,
    setShowModal,
    list,
    setList,
    position,
    setPosition,
    checkValue,
    setCheckValue,
    autoDeleteTime,
    setAutoDeleteTime,
    currentData,
    setCurrentData,
    styles,
    applySorting,
    onChangeFilterModal,
    showToast,
    deleteBatch,
    firstPageRows,
    setFirstPageRows,
    selectPosition,
    onPagination,
    setPageSize,
    searchValues,
    setSearchValues,
    dataNew,
    currentPage,
    PageSize,
    currentTableData,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    preFilteredRows,
    rows,
    page,
    state,
    pageSize,
    dataList,
    setDataList,
    showImage,
    onClickDeleteModal,
    setElements,
    columnSortBy,
    sortBy,
    setSortBy,
    entityType,
    sorting,
    setCustomerDirection,
    setStateCdDirection,
    setCountyNameDirection,
    customerDirection,
    stateCdDirection,
    countyNameDirection,
    setCommunityDirection,
    communityDirection,
    entityDirection,
    clliCdDirection,
    setClliCdDirection,
    setEntityDirection,
    setExchangeDirection,
    agencyDirection,
    setAgencyDirection,
    isBoolean,
    GrayTooltip,
    focus,
    setFocus,
    key1Ref,
    key2Ref,
    key3Ref,
    key4Ref,
    key5Ref,
    key6Ref,
    globalSearchRef,
    addType,
    setAddType,
    tableData,
    setTableData,
    clearSearch,
    clearGlobalSearch,
    setCurrentPage
  }

}




export function ShowTable(selectTableRef,
  getTableProps,
  headerGroups,
  applySorting,
  currentTableData,
  getTableBodyProps,
  prepareRow,
  showImage,
  preFilteredRows,
  dataList,
  deleteBatch,
  onClickTableEditModal,
  onPagination,
  currentPage,
  currentData,
  PageSize,
  page,
  setPageSize,
  sorting,
  entityType,
  isBoolean,
  GrayTooltip) {
  return (

    <div className="flex flex-col px-8 py-2">
      <>

        <table ref={selectTableRef} {...getTableProps()}>
          <thead className="bg-gray-50">
            {headerGroups.map(headerGroup => (

              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    className="py-2 text-left text-xs font-medium text-gray-1000"
                    {...column.getHeaderProps(column.getSortByToggleProps())}


                    onClick={() => (
                      !column.disableSortBy
                        ? (column.toggleSortBy(!column.isSortedDesc), applySorting(column, !sorting.ascending, column.id, headerGroup.headers))
                        : null
                    )}
                  >
                    {column.render("Header")}

                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {currentTableData.length > 0 && entityType != "agency" &&
            <tbody  {...getTableBodyProps()} className="bg-white" >
              {currentTableData.map((row, i) => {
                prepareRow(row);
                return (
                  <tr key={row.id} {...row.getRowProps()}
                    className="text-xs text-left px-10 py-2 whitespace-nowrap"
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td  {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          }

          {currentTableData.length > 0 && entityType == "agency" &&
            <tbody  {...getTableBodyProps()} className="bg-white" >
              {currentTableData.map((row, i) => {
                prepareRow(row);
                return (
                  <tr key={row.id} {...row.getRowProps()}
                    className="text-xs text-left px-10 py-2 whitespace-nowrap"
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <GrayTooltip key={index} title={cell.value ? String(cell.value) : ""} disableHoverListener={false}>
                          <td  {...cell.getCellProps()}>
                            {isBoolean(cell.value)
                              ? (cell.value ? "true" : "false")
                              : cell.render('Cell')
                            }
                          </td>
                        </GrayTooltip>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          }

        </table>


        <>
          <div>{showImage(preFilteredRows)}</div>
        </>

        {dataList && dataList.length > 0 && entityType != "agency" &&
          <div className={style.centered}>

            <button className={style.deletetext} onClick={(e) => {
              { deleteBatch(dataList, entityType) }
            }} > Delete Selected
            </button>
            <span className={style.tableButton}>
            </span>
            <button className={style.deletetext} onClick={(e) => {
              { onClickTableEditModal() }
            }} > Add New Batch
            </button>
          </div>

        }


        <div className={style.centered}>
          {onPagination(currentPage,
            currentData,
            PageSize,
            page,
            currentTableData.length,
            setPageSize)}

        </div>

        <br />
      </>
    </div>

  )

}

function sortCountyTable(initSortBy,
  sorting,
  setCustomerDirection,
  setStateCdDirection,
  setCountyNameDirection,
  customerDirection,
  stateCdDirection,
  countyNameDirection) {

  var sortBy = null;

  sortBy = initSortBy;

  if (sorting.key === "customerId") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: 1
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        }];
      setCustomerDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: -1
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        }, {
          prop: 'countyName',
          direction: countyNameDirection
        }];
      setCustomerDirection(-1);
    }
  }

  if (sorting.key === "stateCd") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: 1
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        }];
      setStateCdDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: -1
        }, {
          prop: 'countyName',
          direction: countyNameDirection
        }];
      setStateCdDirection(-1);
    }
  }


  if (sorting.key === "countyName") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: 1
        }];
      setCountyNameDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        }, {
          prop: 'countyName',
          direction: -1
        }];
      setCountyNameDirection(-1);
    }

  }


  if (sorting.key === "countyCd") {
    sortBy = [
      {
        prop: 'countyCd',
        direction: sorting.ascending ? 1 : -1
      }
    ]

  }

  return {
    sortBy,
    setCustomerDirection,
    setStateCdDirection,
    setCountyNameDirection
  }

}


function sortClliTable(initSortBy,
  sorting,
  setCustomerDirection,
  setStateCdDirection,
  setCountyNameDirection,
  customerDirection,
  stateCdDirection,
  countyNameDirection,
  communityDirection,
  entityDirection,
  clliCdDirection,
  setCommunityDirection,
  setClliCdDirection,
  setEntityDirection) {

  var sortBy = null;

  sortBy = initSortBy;

  if (sorting.key === "customerId") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: 1
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: entityDirection
        },
        {
          prop: 'clliCd',
          direction: clliCdDirection
        }
      ];
      setCustomerDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: -1
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: entityDirection
        },
        {
          prop: 'clliCd',
          direction: clliCdDirection
        }
      ];
      setCustomerDirection(-1);
    }
  }


  if (sorting.key === "stateCd") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: 1
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: entityDirection
        },
        {
          prop: 'clliCd',
          direction: clliCdDirection
        }
      ];
      setStateCdDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: -1
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: entityDirection
        },
        {
          prop: 'clliCd',
          direction: clliCdDirection
        }
      ];
      setStateCdDirection(-1);
    }
  }

  if (sorting.key === "countyName") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: 1
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: entityDirection
        },
        {
          prop: 'clliCd',
          direction: clliCdDirection
        }
      ];
      setCountyNameDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: -1
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: entityDirection
        },
        {
          prop: 'clliCd',
          direction: clliCdDirection
        }
      ];
      setCountyNameDirection(-1);
    }
  }


  if (sorting.key === "community") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: 1
        },
        {
          prop: 'entity',
          direction: entityDirection
        },
        {
          prop: 'clliCd',
          direction: clliCdDirection
        }
      ];
      setCommunityDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: -1
        },
        {
          prop: 'entity',
          direction: entityDirection
        },
        {
          prop: 'clliCd',
          direction: clliCdDirection
        }
      ];
      setCommunityDirection(-1);
    }
  }

  if (sorting.key === "clliCd") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: entityDirection
        },
        {
          prop: 'clliCd',
          direction: 1
        }
      ];
      setClliCdDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: entityDirection
        },
        {
          prop: 'clliCd',
          direction: -1
        }
      ];
      setClliCdDirection(-1);
    }
  }

  if (sorting.key === "entity") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: 1
        },
        {
          prop: 'clliCd',
          direction: clliCdDirection
        }
      ];
      setEntityDirection(1);
    }


    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: -1
        },
        {
          prop: 'clliCd',
          direction: clliCdDirection
        }
      ];
      setEntityDirection(-1);
    }

  }

  return {
    sortBy,
    setCustomerDirection,
    setStateCdDirection,
    setCountyNameDirection,
    setCommunityDirection,
    setClliCdDirection,
    setEntityDirection
  }

}

function sortEntityTable(initSortBy,
  sorting,
  setCustomerDirection,
  setStateCdDirection,
  setCountyNameDirection,
  customerDirection,
  stateCdDirection,
  countyNameDirection,
  communityDirection,
  entityDirection,
  setCommunityDirection,
  setEntityDirection) {

  var sortBy = null;

  sortBy = initSortBy;

  if (sorting.key === "customerId") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: 1
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: entityDirection
        }
      ];
      setCustomerDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: -1
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: entityDirection
        }
      ];
      setCustomerDirection(-1);
    }
  }


  if (sorting.key === "stateCd") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: 1
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: entityDirection
        }
      ];
      setStateCdDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: -1
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: entityDirection
        }
      ];
      setStateCdDirection(-1);
    }
  }

  if (sorting.key === "countyName") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: 1
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: entityDirection
        }
      ];
      setCountyNameDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: -1
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: entityDirection
        }
      ];
      setCountyNameDirection(-1);
    }
  }


  if (sorting.key === "community") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: 1
        },
        {
          prop: 'entity',
          direction: entityDirection
        }
      ];
      setCommunityDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: -1
        },
        {
          prop: 'entity',
          direction: entityDirection
        }
      ];
      setCommunityDirection(-1);
    }
  }


  if (sorting.key === "entity") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: 1
        }
      ];
      setEntityDirection(1);
    }


    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'entity',
          direction: -1
        }
      ];
      setEntityDirection(-1);
    }

  }

  return {
    sortBy,
    setCustomerDirection,
    setStateCdDirection,
    setCountyNameDirection,
    setCommunityDirection,
    setEntityDirection
  }

}

function sortExchangeTable(initSortBy,
  sorting,
  setCustomerDirection,
  setStateCdDirection,
  setCountyNameDirection,
  customerDirection,
  stateCdDirection,
  countyNameDirection,
  communityDirection,
  exchangeDirection,
  setCommunityDirection,
  setExchangeDirection) {

  var sortBy = null;

  sortBy = initSortBy;

  if (sorting.key === "customerId") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: 1
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'exchange',
          direction: exchangeDirection
        }
      ];
      setCustomerDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: -1
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'exchange',
          direction: exchangeDirection
        }
      ];
      setCustomerDirection(-1);
    }
  }


  if (sorting.key === "stateCd") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: 1
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'exchange',
          direction: exchangeDirection
        }
      ];
      setStateCdDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: -1
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'exchange',
          direction: exchangeDirection
        }
      ];
      setStateCdDirection(-1);
    }
  }

  if (sorting.key === "countyName") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: 1
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'exchange',
          direction: exchangeDirection
        }
      ];
      setCountyNameDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: -1
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'exchange',
          direction: exchangeDirection
        }
      ];
      setCountyNameDirection(-1);
    }
  }


  if (sorting.key === "community") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: 1
        },
        {
          prop: 'exchange',
          direction: exchangeDirection
        }
      ];
      setCommunityDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: -1
        },
        {
          prop: 'exchange',
          direction: exchangeDirection
        }
      ];
      setCommunityDirection(-1);
    }
  }


  if (sorting.key === "exchange") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'exchange',
          direction: 1
        }
      ];
      setExchangeDirection(1);
    }


    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: customerDirection
        },
        {
          prop: 'stateCd',
          direction: stateCdDirection
        },
        {
          prop: 'countyName',
          direction: countyNameDirection
        },
        {
          prop: 'community',
          direction: communityDirection
        },
        {
          prop: 'exchange',
          direction: -1
        }
      ];
      setExchangeDirection(-1);
    }

  }

  return {
    sortBy,
    setCustomerDirection,
    setStateCdDirection,
    setCountyNameDirection,
    setCommunityDirection,
    setExchangeDirection
  }

}


function sortAgencyTable(initSortBy,
  sorting,
  setCustomerDirection,
  customerDirection,
  agencyDirection,
  setAgencyDirection) {

  var sortBy = null;

  sortBy = initSortBy;

  if (sorting.key === "agencyId") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'agencyId',
          direction: 1
        },
        {
          prop: 'customerId',
          direction: customerDirection
        }];
      setAgencyDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'agencyId',
          direction: -1
        },
        {
          prop: 'customerId',
          direction: customerDirection
        }];
      setAgencyDirection(-1);
    }
  }
  else if (sorting.key === "customerId") {
    if (sorting.ascending) {
      sortBy = [
        {
          prop: 'agencyId',
          direction: agencyDirection
        },
        {
          prop: 'customerId',
          direction: 1
        }];
      setCustomerDirection(1);
    }

    if (!sorting.ascending) {
      sortBy = [
        {
          prop: 'customerId',
          direction: -1
        },
        {
          prop: 'agencyId',
          direction: agencyDirection
        }];
      setCustomerDirection(-1);
    }

  }
  else {
    sortBy = [
      {
        prop: sorting.key,
        direction: sorting.ascending ? 1 : -1
      }
    ]
  }


  return {
    sortBy,
    setAgencyDirection,
    setCustomerDirection
  }


}


export function BasicTable({
  props,
  columns,
  data,
  getDataWithAxios,
  setShowEditModal,
  showEditModal,
  onClickEditModal,
  rowId,
  setShowAddModal,
  showAddModal,
  showDeleteModal,
  setShowDeleteModal,
  onClickDeleteModal,
  countyCd,
  sorting,
  setSorting,
  PageSize,
  currentPage,
  setCurrentPage,
  initialState,
  customerDirection,
  setCustomerDirection,
  stateCdDirection,
  setStateCdDirection,
  countyNameDirection,
  setCountyNameDirection,
  setPageSize,
  searchValues,
  setSearchValues,
  initialSearchValues,
  dataList,
  deleteType,
  setDeleteType,
  setDataList,
  setRowChecked,
  initialCheckValues,
  selectTableRef,
  rowChecked,
  elements,
  setElements,
  setShowEditTableModal,
  showEditTableModal,
  onClickTableEditModal,
  showImage,
  optionsStateValue,
  optionsCountyValue,
  setOptionsStateValue,
  setOptionsCountyValue,
  selectInputCountyRef,
  selectInputStateRef,
  setAllSelected,
  selectAllRef,
  columnSortBy,
  entity,
  entityType,
  tableTitle,
  communityDirection,
  setCommunityDirection,
  entityDirection,
  setEntityDirection,
  clliCdDirection,
  setClliCdDirection,
  clliCd,
  exchangeDirection,
  setExchangeDirection,
  agencyDirection,
  setAgencyDirection,
  agencyId,
  onClickModal,
  onCloseModal,
  showModal,
  setShowModal,
  onCloseEditModal,
  onCloseDeleteModal,
  onClickAddModal,
  addType,
  setAddType,
  tableData,
  setTableData,
  baseURL
}) {

  let tableProps = InitialTableValues(props,
    columns,
    data,
    initialState,
    sorting,
    setSorting,
    currentPage,
    setCurrentPage,
    setSearchValues,
    initialSearchValues,
    PageSize,
    setPageSize,
    searchValues,
    dataList,
    setDataList,
    showImage,
    onClickDeleteModal,
    setElements,
    columnSortBy,
    entityType,
    setCustomerDirection,
    setStateCdDirection,
    setCountyNameDirection,
    customerDirection,
    stateCdDirection,
    countyNameDirection,
    communityDirection,
    setCommunityDirection,
    entityDirection,
    setEntityDirection,
    clliCdDirection,
    setClliCdDirection,
    exchangeDirection,
    setExchangeDirection,
    agencyDirection,
    setAgencyDirection,
    agencyId,
    addType,
    setAddType,
    tableData,
    setTableData,
  );


  return (
    <>
      <div>

        <Toast toastList={tableProps.list}
          position={tableProps.position}
          autoDelete={tableProps.checkValue}
          autoDeleteTime={tableProps.autoDeleteTime}
        />

        <GlobalTable {...props}
          {...tableProps}
        />

        {tableProps.firstPageRows.length > 0 &&
          <div className="md:container py-0">

            <div className="md:container py-0">
              <div className="md:flex md:gap-x-3">
                <GlobalFilter
                  preGlobalFilteredRows={tableProps.preGlobalFilteredRows}
                  globalFilter={tableProps.state.globalFilter}
                  setGlobalFilter={tableProps.setGlobalFilter}
                  columns={columns}
                  preFilteredRows={tableProps.preFilteredRows}
                  headerGroups={tableProps.headerGroups}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  onChangeFilterModal={tableProps.onChangeFilterModal}
                  getDataWithAxios={getDataWithAxios}
                  setShowEditModal={setShowEditModal}
                  showEditModal={showEditModal}
                  setShowAddModal={setShowAddModal}
                  showAddModal={showAddModal}
                  onClickEditModal={onClickEditModal}
                  rowId={rowId}
                  setShowDeleteModal={setShowDeleteModal}
                  showDeleteModal={showDeleteModal}
                  onClickDeleteModal={onClickDeleteModal}
                  countyCd={countyCd}
                  showToast={tableProps.showToast}
                  setStateFilters={tableProps.setStateFilters}
                  setCountyFilters={tableProps.setCountyFilters}
                  stateFilters={tableProps.stateFilters}
                  countyFilters={tableProps.countyFilters}
                  filteredRows={tableProps.filteredRows}
                  setFilteredRows={tableProps.setFilteredRows}
                  setCurrentData={tableProps.setCurrentData}
                  searchValues={searchValues}
                  setSearchValues={setSearchValues}
                  initialSearchValues={initialSearchValues}
                  deleteType={deleteType}
                  setDeleteType={setDeleteType}
                  dataList={dataList}
                  setDataList={setDataList}
                  setRowChecked={setRowChecked}
                  initialCheckValues={initialCheckValues}
                  selectTableRef={selectTableRef}
                  rowChecked={rowChecked}
                  elements={elements}
                  setElements={setElements}
                  setShowEditTableModal={setShowEditTableModal}
                  showEditTableModal={showEditTableModal}
                  onClickTableEditModal={onClickTableEditModal}
                  currentTableData={tableProps.currentTableData}
                  optionsStateValue={optionsStateValue}
                  optionsCountyValue={optionsCountyValue}
                  setOptionsStateValue={setOptionsStateValue}
                  setOptionsCountyValue={setOptionsCountyValue}
                  selectInputCountyRef={selectInputCountyRef}
                  selectInputStateRef={selectInputStateRef}
                  setAllSelected={setAllSelected}
                  selectAllRef={selectAllRef}
                  entityType={tableProps.entityType}
                  tableTitle={tableTitle}
                  agencyId={agencyId}
                  focus={tableProps.focus}
                  setFocus={tableProps.setFocus}
                  key1Ref={tableProps.key1Ref}
                  key2Ref={tableProps.key2Ref}
                  key3Ref={tableProps.key3Ref}
                  key4Ref={tableProps.key4Ref}
                  key5Ref={tableProps.key5Ref}
                  key6Ref={tableProps.key6Ref}
                  globalSearchRef={tableProps.globalSearchRef}
                  onClickModal={onClickModal}
                  onCloseModal={onCloseModal}
                  onCloseEditModal={onCloseEditModal}
                  onCloseDeleteModal={onCloseDeleteModal}
                  onClickAddModal={onClickAddModal}
                  addType={tableProps.addType}
                  setAddType={tableProps.setAddType}
                  tableData={tableProps.tableData}
                  setTableData={tableProps.setTableData}
                  clearSearch={tableProps.clearSearch}
                  clearGlobalSearch={tableProps.clearGlobalSearch}
                  setCurrentPage={tableProps.setCurrentPage}
                  baseURL={baseURL}
                />

              </div>
            </div>

            {ShowTable(
              selectTableRef,
              tableProps.getTableProps,
              tableProps.headerGroups,
              tableProps.applySorting,
              tableProps.currentTableData,
              tableProps.getTableBodyProps,
              tableProps.prepareRow,
              tableProps.showImage,
              tableProps.preFilteredRows,
              tableProps.dataList,
              tableProps.deleteBatch,
              onClickTableEditModal,
              tableProps.onPagination,
              tableProps.currentPage,
              tableProps.currentData,
              tableProps.PageSize,
              tableProps.page,
              tableProps.setPageSize,
              tableProps.sorting,
              tableProps.entityType,
              tableProps.isBoolean,
              tableProps.GrayTooltip)}


          </div>
        }


      </div>
    </>

  )

  function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    columns,
    preFilteredRows,
    headerGroups,
    showModal,
    setShowModal,
    onChangeFilterModal,
    filteredUsers,
    getDataWithAxios,
    setShowEditModal,
    showEditModal,
    setShowAddModal,
    showAddModal,
    onClickEditModal,
    rowId,
    setShowDeleteModal,
    showDeleteModal,
    onClickDeleteModal,
    countyCd,
    showToast,
    setStateFilters,
    setCountyFilters,
    stateFilters,
    countyFilters,
    filteredRows,
    setFilteredRows,
    setCurrentData,
    searchValues,
    setSearchValues,
    initialSearchValues,
    deleteType,
    setDeleteType,
    dataList,
    setDataList,
    setRowChecked,
    initialCheckValues,
    selectTableRef,
    rowChecked,
    elements,
    setElements,
    setShowEditTableModal,
    showEditTableModal,
    onClickTableEditModal,
    currentTableData,
    optionsStateValue,
    optionsCountyValue,
    setOptionsStateValue,
    setOptionsCountyValue,
    selectInputCountyRef,
    selectInputStateRef,
    setAllSelected,
    selectAllRef,
    entityType,
    tableTitle,
    communityDirection,
    setCommunityDirection,
    entityDirection,
    setEntityDirection,
    clliCdDirection,
    setClliCdDirection,
    clliCd,
    exchangeDirection,
    setExchangeDirection,
    agencyDirection,
    setAgencyDirection,
    agencyId,
    focus,
    setFocus,
    key1Ref,
    key2Ref,
    key3Ref,
    key4Ref,
    key5Ref,
    key6Ref,
    globalSearchRef,
    onClickModal,
    onCloseModal,
    onCloseEditModal,
    onCloseDeleteModal,
    onClickAddModal,
    addType,
    setAddType,
    tableData,
    setTableData,
    clearSearch,
    clearGlobalSearch,
    ...props
  }) {

    let filterProps = GlobalFilterTranslations.InitialTableValues(props,
      globalFilter,
      preGlobalFilteredRows,
      setShowModal,
      setShowAddModal,
      setShowEditModal,
      setShowDeleteModal,
      setDeleteType,
      setGlobalFilter,
      setCurrentData,
      filteredRows,
      preFilteredRows,
      selectInputCountyRef,
      selectInputStateRef,
      showModal,
      onChangeFilterModal,
      optionsStateValue,
      setOptionsStateValue,
      optionsCountyValue,
      setOptionsCountyValue,
      setStateFilters,
      setCountyFilters,
      setFilteredRows,
      headerGroups,
      showAddModal,
      getDataWithAxios,
      showToast,
      stateFilters,
      showEditModal,
      rowId,
      showDeleteModal,
      countyCd,
      deleteType,
      dataList,
      setDataList,
      setRowChecked,
      initialCheckValues,
      selectTableRef,
      rowChecked,
      elements,
      setElements,
      initialSearchValues,
      setSearchValues,
      setAllSelected,
      selectAllRef,
      showEditTableModal,
      setShowEditTableModal,
      onClickTableEditModal,
      currentTableData,
      entityType,
      tableTitle,
      searchValues,
      agencyId,
      focus,
      setFocus,
      key1Ref,
      key2Ref,
      key3Ref,
      key4Ref,
      key5Ref,
      key6Ref,
      globalSearchRef,
      null,
      onClickModal,
      onCloseModal,
      onCloseEditModal,
      onClickDeleteModal,
      onCloseDeleteModal,
      onClickAddModal,
      addType,
      setAddType,
      tableData,
      setTableData,
      clearSearch,
      clearGlobalSearch,
      setCurrentPage
    );

    return (

      <>



        <GlobalTableFilter tableData={filterProps.tableData} setTableData={filterProps.setTableData}
          setAddType={filterProps.setAddType} addType={filterProps.addType} entityType={filterProps.entityType}

        />
        <div className={style.selectionArea}>
          <div className="md:container md:mx-8 grid grid-cols-12 grid-rows-1 py-1">

            {entityType == "county" && <div className="col-span-2 text-left py-1" ><h3 className={style.h1text}>County ID Translation</h3></div>}
            {entityType == "clli" && <div className="col-span-2 text-left py-1" ><h3 className={style.h1text}>CLLI Translation</h3></div>}
            {entityType == "entity" && <div className="col-span-2 text-left py-1" ><h3 className={style.h1text}>Entity Translation</h3></div>}
            {entityType == "exchange" && <div className="col-span-2 text-left py-1" ><h3 className={style.h1text}>Exchange Translation</h3></div>}
            {entityType == "agency" && <div className="col-span-3 text-left py-1" ><h3 className={style.h1text}>Agency Maintenance</h3></div>}

            {entityType == "county" &&
              <div className="col-span-6 text-left px-4">
                <TableGeneralSearch searchValues={filterProps.searchValues}
                  setSearchValues={filterProps.setSearchValues}
                  key1={filterProps.searchValues.countyCd}
                  keyName1={"countyCd"}
                  placeHolder1={"County Ids"}
                  key2={filterProps.searchValues.customerId}
                  keyName2={"customerId"}
                  placeHolder2={"Customer Ids"}
                  key3={filterProps.searchValues.stateCd}
                  keyName3={"stateCd"}
                  placeHolder3={"States"}
                  key4={filterProps.searchValues.countyName}
                  keyName4={"countyName"}
                  placeHolder4={"Counties"}
                  key5={null}
                  key6={null}
                  initialSearchValues={filterProps.initialSearchValues}
                  focus={focus}
                  setFocus={setFocus}
                  key1Ref={key1Ref}
                  key2Ref={key2Ref}
                  key3Ref={key3Ref}
                  key4Ref={key4Ref}
                  key5Ref={key5Ref}
                  key6Ref={key6Ref}
                  clearGlobalSearch={clearGlobalSearch}
                  globalSearchMulti={filterProps.globalSearchMulti}
                  entityType={entityType}
                  setCurrentData={filterProps.setCurrentData}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            }
            {entityType == "entity" &&
              <div className="col-span-6 text-left px-0">
                <TableGeneralSearch searchValues={filterProps.searchValues}
                  setSearchValues={filterProps.setSearchValues}
                  key1={filterProps.searchValues.entity}
                  keyName1={"entity"}
                  placeHolder1={"Entities"}
                  key2={filterProps.searchValues.community}
                  keyName2={"community"}
                  placeHolder2={"Communities"}
                  key3={filterProps.searchValues.countyName}
                  keyName3={"countyName"}
                  placeHolder3={"Counties"}
                  key4={filterProps.searchValues.stateCd}
                  keyName4={"stateCd"}
                  placeHolder4={"States"}
                  key5={filterProps.searchValues.customerId}
                  keyName5={"customerId"}
                  placeHolder5={"Customer Ids"}
                  key6={null}
                  initialSearchValues={filterProps.initialSearchValues}
                  focus={focus}
                  setFocus={setFocus}
                  key1Ref={key1Ref}
                  key2Ref={key2Ref}
                  key3Ref={key3Ref}
                  key4Ref={key4Ref}
                  key5Ref={key5Ref}
                  key6Ref={key6Ref}
                  clearGlobalSearch={clearGlobalSearch}
                  globalSearchMulti={filterProps.globalSearchMulti}
                  entityType={entityType}
                  setCurrentData={filterProps.setCurrentData}
                  setCurrentPage={setCurrentPage}
                />


              </div>
            }
            {entityType == "clli" &&
              <div className="col-span-6 text-left">
                <TableGeneralSearch searchValues={filterProps.searchValues}
                  setSearchValues={filterProps.setSearchValues}
                  key1={filterProps.searchValues.customerId}
                  keyName1={"customerId"}
                  placeHolder1={"Customer Ids"}
                  key2={filterProps.searchValues.stateCd}
                  keyName2={"stateCd"}
                  placeHolder2={"States"}
                  key3={filterProps.searchValues.countyName}
                  keyName3={"countyName"}
                  placeHolder3={"Counties"}
                  key4={filterProps.searchValues.community}
                  keyName4={"community"}
                  placeHolder4={"Communities"}
                  key5={filterProps.searchValues.entity}
                  keyName5={"entity"}
                  placeHolder5={"Entities"}
                  key6={filterProps.searchValues.clliCd}
                  keyName6={"clliCd"}
                  placeHolder6={"clliCd"}
                  initialSearchValues={filterProps.initialSearchValues}
                  focus={focus}
                  setFocus={setFocus}
                  key1Ref={key1Ref}
                  key2Ref={key2Ref}
                  key3Ref={key3Ref}
                  key4Ref={key4Ref}
                  key5Ref={key5Ref}
                  key6Ref={key6Ref}
                  clearGlobalSearch={clearGlobalSearch}
                  globalSearchMulti={filterProps.globalSearchMulti}
                  entityType={entityType}
                  setCurrentData={filterProps.setCurrentData}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            }
            {entityType == "exchange" &&
              <div className="col-span-6 text-left">
                <TableGeneralSearch searchValues={filterProps.searchValues}
                  setSearchValues={filterProps.setSearchValues}
                  key1={filterProps.searchValues.customerId}
                  keyName1={"customerId"}
                  placeHolder1={"Customer Ids"}
                  key2={filterProps.searchValues.stateCd}
                  keyName2={"stateCd"}
                  placeHolder2={"States"}
                  key3={filterProps.searchValues.countyName}
                  keyName3={"countyName"}
                  placeHolder3={"Counties"}
                  key4={filterProps.searchValues.community}
                  keyName4={"community"}
                  placeHolder4={"Communities"}
                  key5={filterProps.searchValues.exchange}
                  keyName5={"exchange"}
                  placeHolder5={"Exchanges"}
                  key6={null}
                  initialSearchValues={filterProps.initialSearchValues}
                  focus={focus}
                  setFocus={setFocus}
                  key1Ref={key1Ref}
                  key2Ref={key2Ref}
                  key3Ref={key3Ref}
                  key4Ref={key4Ref}
                  key5Ref={key5Ref}
                  key6Ref={key6Ref}
                  clearGlobalSearch={clearGlobalSearch}
                  globalSearchMulti={filterProps.globalSearchMulti}
                  entityType={entityType}
                  setCurrentData={filterProps.setCurrentData}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            }
            {entityType == "agency" &&
              <div className="col-span-5 text-left px-1">
                <TableGeneralSearch searchValues={filterProps.searchValues}
                  setSearchValues={filterProps.setSearchValues}
                  key1={filterProps.searchValues.agencyId}
                  keyName1={"agencyId"}
                  placeHolder1={"Agency Ids"}
                  key2={filterProps.searchValues.agencyName}
                  keyName2={"agencyName"}
                  placeHolder2={"Agency Names"}
                  key3={filterProps.searchValues.customerId}
                  keyName3={"customerId"}
                  placeHolder3={"Customer Ids"}
                  key4={null}
                  key5={null}
                  key6={null}
                  initialSearchValues={filterProps.initialSearchValues}
                  focus={focus}
                  setFocus={setFocus}
                  key1Ref={key1Ref}
                  key2Ref={key2Ref}
                  key3Ref={key3Ref}
                  key4Ref={key4Ref}
                  key5Ref={key5Ref}
                  key6Ref={key6Ref}
                  clearGlobalSearch={clearGlobalSearch}
                  globalSearchMulti={filterProps.globalSearchMulti}
                  entityType={entityType}
                  setCurrentData={filterProps.setCurrentData}
                  setCurrentPage={setCurrentPage}
                />
              </div>

            }


            {GlobalFilterTranslations.ShowTableHeader(filterProps.value,
              filterProps.count,
              filterProps.setValue,
              filterProps.onChange,
              filterProps.globalSearch,
              filterProps.entityType,
              filterProps.setFocus,
              filterProps.globalSearchRef,
              clearSearch)}


            {entityType != "agency" &&
              <div className="col-span-1 text-center">
                <button onClick={filterProps.onClickModal} className={"mt-2.5"}>
                  <img src={flist} />
                </button>
              </div>
            }
            {entityType == "agency" && <div className="col-span-1 text-left">
            </div>
            }

            <div className="col-span-1 text-right ">
              <button className={style.buttontext} onClick={filterProps.onClickAddModal} > Add New </button>
            </div>
          </div>
          {GlobalFilterTranslations.ShowModals(
            filterProps.preFilteredRows,
            filterProps.headerGroups,
            filterProps.selectInputCountyRef,
            filterProps.selectInputStateRef,
            filterProps.showModal,
            filterProps.onCloseModal,
            filterProps.onChangeFilterModal,
            filterProps.optionsValue,
            filterProps.setOptionsValue,
            filterProps.optionsStateValue,
            filterProps.setOptionsStateValue,
            filterProps.optionsCountyValue,
            filterProps.setOptionsCountyValue,
            filterProps.setStateFilters,
            filterProps.setCountyFilters,
            filterProps.filteredRows,
            filterProps.setFilteredRows,
            filterProps.setCurrentData,
            filterProps.showAddModal,
            filterProps.navigate,
            filterProps.onCloseAddModal,
            filterProps.getDataWithAxios,
            filterProps.setShowAddModal,
            filterProps.showToast,
            filterProps.stateFilters,
            filterProps.showEditModal,
            filterProps.onCloseEditModal,
            filterProps.rowId,
            filterProps.showDeleteModal,
            filterProps.onCloseDeleteModal,
            filterProps.setShowDeleteModal,
            filterProps.countyCd,
            filterProps.deleteType,
            filterProps.dataList,
            filterProps.setDataList,
            filterProps.setRowChecked,
            filterProps.initialCheckValues,
            filterProps.selectTableRef,
            filterProps.rowChecked,
            filterProps.elements,
            filterProps.setElements,
            filterProps.initialSearchValues,
            filterProps.setSearchValues,
            filterProps.setAllSelected,
            filterProps.selectAllRef,
            filterProps.setValue,
            filterProps.showEditTableModal,
            filterProps.setShowEditTableModal,
            filterProps.currentTableData,
            filterProps.tableTitle,
            filterProps.preGlobalFilteredRows,
            filterProps.tableData,
            filterProps.initialState,
            filterProps.setErrorMessage,
            filterProps.errorMessage,
            filterProps.setTableData,
            filterProps.buttonDisabled,
            filterProps.setButtonDisabled,
            filterProps.entityType,
            filterProps.entity,
            filterProps.exchange,
            filterProps.agencyId,
            baseURL,
            filterProps.currentData
          )}
        </div>

      </>
    )


  }


}



export default GlobalTable;